import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  getActivetables,
  getCategoryInfo,
  getItemInfo,
  getPreferences,
  getSongsQueue,
  getCurrentSong,
  getTableInfo,
  getUserInQueue,
  getWaiterDetails,
  getStoreLoyalty,
} from './actions';
import {ablyAck} from './actions/miscActions';
import {
  updateOrder,
  getOrders,
  getServices,
  getMultiDropOrders,
  updateMultiDropOrder,
} from './actions/orderActions';

function Ably() {
  const dispatch = useDispatch();
  const restID = useSelector(
    (state) => state.auth.restData && state.auth.restData.store_id
  );

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  /** connect to restaurant channel when restID updated */
  useEffect(() => {
    if (restID && dashboardAccess && window.restaurantChannel) {
      const orderListener = (message) => {
        console.log('order---->', message.data);
        if (message.data.data && message.data.data.order_id) {
          dispatch(updateOrder(message.data.data.order_id));
        }
        dispatch(ablyAck(message.data.ably_id));
      };
      const servicesListener = (message) => {
        console.log('services---->', message.data);
        dispatch(getServices());
        dispatch(ablyAck(message.data.ably_id));
      };
      const multiDropListener = (message) => {
        console.log('multi drop---->', message.data);
        if (message.data.data && message.data.data.order_id) {
          dispatch(updateMultiDropOrder(message.data.data.order_id));
        }
        dispatch(ablyAck(message.data.ably_id));
      };
      const qmsListener = (message) => {
        console.log('qms---->', message.data);
        if (message.data.data) {
          dispatch(getActivetables(restID));
          dispatch(getUserInQueue(restID));
        }
        dispatch(ablyAck(message.data.ably_id));
      };
      window.restaurantChannel.attach();
      window.restaurantChannel.once('attached', () => {
        console.log('%cably restaurant channel connected', 'color: green;');
        if (dashboardAccess.includes('cashier')) {
          dispatch(getTableInfo(restID));
          dispatch(getItemInfo(restID));
          dispatch(getCategoryInfo());
          dispatch(getOrders());
          dispatch(getServices());
          dispatch(getMultiDropOrders());
          dispatch(getPreferences());
          dispatch(getWaiterDetails());
          dispatch(getSongsQueue());
          dispatch(getCurrentSong());
          dispatch(getStoreLoyalty());
          window.restaurantChannel.subscribe('order', orderListener);
          window.restaurantChannel.subscribe('services', servicesListener);
          window.restaurantChannel.subscribe('order_group', multiDropListener);
        }
        if (dashboardAccess.includes('qms')) {
          if (!dashboardAccess.includes('cashier')) {
            dispatch(getTableInfo(restID));
            dispatch(getPreferences());
          }
          dispatch(getActivetables(restID));
          dispatch(getUserInQueue(restID));
          window.restaurantChannel.subscribe('qms', qmsListener);
        }
      });
    } else if (window.restaurantChannel && window.restaurantChannel.name) {
      window.restaurantChannel.detach();
      window.restaurantChannel.unsubscribe();
      window.restaurantChannel = null;
    }
  }, [restID, dashboardAccess, dispatch]);

  return <></>;
}

export default Ably;

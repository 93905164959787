import React from 'react';

function ActiveTableIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path fill="#a1887f" d="M6 12L1 33 11 33z" />
      <path fill="#795548" d="M6 12L6 33 11 33z" />
      <path fill="#ffecb3" d="M6 12L9 36 47 36 42 12z" />
      <path fill="#8d6e63" d="M15 23H38V25H15z" />
    </svg>
  );
}

export default ActiveTableIcon;

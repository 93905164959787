import {API_URL} from '../../../utils/constants';
import {http} from '../../http';

export const setCurrentSelectedHold = (data) => ({
  type: 'CURRENT_SELECTED_HOLD',
  payload: data,
});

export const createHoldQueue = (type, id, tableNO) => ({
  type: 'CREATE_HOLD_QUEUE',
  payload: {
    type: type,
    user_name: '',
    user_phone: '',
    items: [],
    table_no: tableNO,
    _id: id,
  },
});

export const createDeliveryHoldQueue = (type, id) => ({
  type: 'CREATE_HOLD_QUEUE',
  payload: {type: type, name: '', items: [], phone: '', _id: id, address: {}},
});

export const createMultipleDeliveryHoldQueue = (type, id) => ({
  type: 'CREATE_HOLD_QUEUE',
  payload: {
    type: type,
    _id: id,
    data: [{name: '', items: [], phone: '', address: {}}],
  },
});

export const addMultipleDeliveryHoldQueue = (data) => ({
  type: 'ADD_NEW_DATA_HOLD_QUEUE',
  payload: data,
});

export const setActiveMultiDeliveryAccordian = (data) => ({
  type: 'ACTIVE_MULTI_DELIVERY_ACCORDIAN',
  payload: data,
});

export const saveDeliveryDetails = (id, name, phone, address) => ({
  type: 'UPDATE_UD_HOLD_QUEUE',
  payload: {name: name, phone: phone, _id: id, address: address},
});

export const saveMultiDropDeliveryDetails = (
  id,
  name,
  phone,
  address,
  index
) => ({
  type: 'UPDATE_MULTIDROP_UD_HOLD_QUEUE',
  payload: {
    name: name,
    phone: phone,
    _id: id,
    address: address,
    index: parseInt(index),
  },
});

export const updateCustomerName = (id, name) => ({
  type: 'UPDATE_CUSTOMER_NAME',
  payload: {_id: id, user_name: name},
});

export const updateCustomerNumber = (id, number) => ({
  type: 'UPDATE_CUSTOMER_NUMBER',
  payload: {_id: id, user_phone: number},
});

export const addItemToHoldQueue = (id, item) => ({
  type: 'ADD_ITEM_TO_HOLD_QUEUE',
  payload: {_id: id, item: item},
});

export const addItemToMultiDropHoldQueue = (id, item, activeAccordian) => ({
  type: 'ADD_ITEM_TO_MULTIDROP_HOLD_QUEUE',
  payload: {_id: id, item: item, index: parseInt(activeAccordian)},
});

export const incrementHoldQueueItem = (id, item) => ({
  type: 'INCREMENT_ITEM_HOLD_QUEUE',
  payload: {_id: id, item: item},
});

export const incrementMultiDropHoldQueueItem = (id, item, activeAccordian) => ({
  type: 'INCREMENT_MULTIDROP_ITEM_HOLD_QUEUE',
  payload: {_id: id, item: item, index: parseInt(activeAccordian)},
});

export const complimentHoldQueueItem = (id, item, reason) => ({
  type: 'COMPLIMENT_ITEM_HOLD_QUEUE',
  payload: {_id: id, item: item, reason: reason},
});

export const decrementHoldQueueItem = (id, item) => ({
  type: 'DECREMENT_ITEM_HOLD_QUEUE',
  payload: {_id: id, item: item},
});

export const decrementMultiDropHoldQueueItem = (id, item, activeAccordian) => ({
  type: 'DECREMENT_MULTIDROP_ITEM_HOLD_QUEUE',
  payload: {_id: id, item: item, index: parseInt(activeAccordian)},
});

export const removeItemFromHoldQueue = (id, item) => ({
  type: 'REMOVE_ITEM_FROM_HOLD_QUEUE',
  payload: {_id: id, item: item},
});

export const removeItemfromMultiDropHoldQueue = (
  id,
  item,
  activeAccordian
) => ({
  type: 'REMOVE_ITEM_FROM_MULTIDROP_HOLD_QUEUE',
  payload: {_id: id, item: item, index: parseInt(activeAccordian)},
});

export const removeHoldQueue = (id) => ({
  type: 'REMOVE_HOLD_QUEUE',
  payload: id,
});

export const updateNote = (id, item, value) => ({
  type: 'UPDATE_NOTE_HOLD_QUEUE',
  payload: {_id: id, item: item, value: value},
});

export const updateMultiDropNote = (id, item, value, activeAccordian) => ({
  type: 'UPDATE_MULTIDROP_NOTE_HOLD_QUEUE',
  payload: {
    _id: id,
    item: item,
    value: value,
    index: parseInt(activeAccordian),
  },
});

export const dineinAddItemToHoldQueue = (id, item) => {
  return (dispatch, getState) => {
    const holdQueue = getState().hold.holdQueue;
    const index = holdQueue.findIndex((a) => a._id === id);
    if (index > -1) {
      dispatch(addItemToHoldQueue(id, item));
    } else {
      const getAddonPrice = (item) => {
        if (item.addons.length === 0) {
          return item.base_price;
        } else {
          let temp = item.base_price;
          item.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };
      const tempItem = {
        item_id: item._id,
        name: item.name,
        item_price: getAddonPrice(item),
        total_price: getAddonPrice(item),
        addons: item.addons,
        note: '',
        qty: 1,
      };
      dispatch({
        type: 'CREATE_HOLD_QUEUE',
        payload: {type: 'dinein', items: [tempItem], _id: id},
      });
    }
  };
};

export const getDeliveryUser = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/stores/orders/users`)
      .then((res) => {
        dispatch({type: 'GET_DELIVERY_USERS', payload: res.data});
      })
      .catch(() => {});
  };
};

export const getSubscribers = () => {
  return (dispatch) => {
    http
      .get(`${API_URL}/preorder`)
      .then((res) => {
        dispatch({type: 'INIT_SUBSCRIBERS', payload: res.data});
      })
      .catch(() => {});
  };
};

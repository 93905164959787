import React from 'react';

function PreferencesIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520 520"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#57606f"
        d="M442.5,225v158.5c0,17.4-14.1,31.5-31.5,31.5c-0.149,0-316.889,0-317,0 c-17.39,0-31.5-14.1-31.5-31.5V225H442.5z"
      />
      <rect width="40" height="80" x="352.5" y="335" fill="#414a56" />
      <rect width="40" height="80" x="112.5" y="335" fill="#414a56" />
      <path
        fill="#d7deed"
        d="M152.5,35c0,15.475,0,126.66,0,140.01h200c0-104.347,0-35.876,0-140.01H152.5z"
      />
      <path
        fill="#414a56"
        d="M371.33,175c3.311,7.23,5.57,15.02,6.62,23.2c0.771,5.97,5.87,10.42,11.89,10.42h0.351 c7.229,0,12.82-6.34,11.899-13.51c-0.89-6.94-2.449-13.66-4.619-20.11H411c4.46,0,8.71,0.93,12.55,2.61 c1.591,6.47,2.69,13.12,3.271,19.93c0.53,6.25,5.69,11.08,11.95,11.08h0.35c1.18,0,2.311-0.17,3.38-0.48V225h-380v-18.5 c0-17.4,14.11-31.5,31.5-31.5h58.5h149.23c1.25,1.07,2.74,1.87,4.399,2.33c10.98,3.03,19.641,11.68,22.66,22.67 c1.41,5.13,6.19,8.62,11.51,8.62h0.36c7.9,0,13.68-7.51,11.58-15.13c-1.86-6.75-4.93-12.99-8.99-18.48l0.011-0.01h9.239H371.33z"
      />
      <path d="M411,420H94c-20.126,0-36.5-16.374-36.5-36.5v-177c0-20.126,16.374-36.5,36.5-36.5h207.73c2.761,0,5,2.239,5,5 s-2.239,5-5,5H94c-14.612,0-26.5,11.888-26.5,26.5v177c0,14.612,11.888,26.5,26.5,26.5h317c14.612,0,26.5-11.888,26.5-26.5V208.14 c0-2.761,2.239-5,5-5c2.762,0,5,2.239,5,5V383.5C447.5,403.626,431.126,420,411,420z" />
      <path
        fill="#d7deed"
        d="M152.5,335c0,14.485,0,134.113,0,150h200c0-15.879,0-135.526,0-150H152.5z"
      />
      <path
        fill="#62d8f9"
        d="M457.5,45c11.05,0,20,8.95,20,20s-8.95,20-20,20c-11.04,0-20-8.95-20-20S446.46,45,457.5,45z"
      />
      <path
        fill="#fce25c"
        d="M397.5,255c8.29,0,15,6.72,15,15s-6.71,15-15,15c-8.279,0-15-6.72-15-15S389.221,255,397.5,255z"
      />
      <path
        fill="#1dd08d"
        d="M347.5,255c8.29,0,15,6.72,15,15s-6.71,15-15,15c-8.279,0-15-6.72-15-15S339.221,255,347.5,255z"
      />
      <circle cx="72.5" cy="90" r="30" fill="#62d8f9" />
      <path
        fill="#4a78ff"
        d="M438.771,208.62c-6.26,0-11.42-4.83-11.95-11.08c-0.58-6.81-1.68-13.46-3.271-19.93 c-9.42-38.43-35.949-70.13-71.05-86.57c-13.529-6.34-28.33-10.42-43.91-11.73c-6.25-0.53-11.09-5.69-11.09-11.96V67 c0-7,5.971-12.54,12.95-11.96c14.7,1.22,28.811,4.51,42.05,9.57v0.01c54.11,20.67,93.58,70.97,98.58,131.05 c0.48,5.81-3.27,10.92-8.58,12.46v0.01c-1.069,0.31-2.2,0.48-3.38,0.48H438.771z"
      />
      <path
        fill="#2663f0"
        d="M352.5,118.64c20.92,12.81,37.03,32.71,44.96,56.36h0.011c2.17,6.45,3.729,13.17,4.619,20.11 c0.921,7.17-4.67,13.51-11.899,13.51h-0.351c-6.02,0-11.119-4.45-11.89-10.42c-1.05-8.18-3.31-15.97-6.62-23.2 c-4.5-9.89-10.939-18.72-18.83-26c-12.05-11.13-27.479-18.64-44.58-20.83c-5.96-0.76-10.42-5.87-10.42-11.88v-0.36 c0-7.22,6.34-12.82,13.511-11.9C326.061,105.95,340.12,111.05,352.5,118.64z"
      />
      <path
        fill="#4a78ff"
        d="M352.24,193.49c2.1,7.62-3.68,15.13-11.58,15.13h-0.36c-5.319,0-10.1-3.49-11.51-8.62 c-3.02-10.99-11.68-19.64-22.66-22.67c-1.659-0.46-3.149-1.26-4.399-2.33c-2.63-2.22-4.23-5.57-4.23-9.18v-0.36 c0-7.9,7.521-13.68,15.13-11.58c12.45,3.43,23.181,11,30.62,21.13C347.311,180.5,350.38,186.74,352.24,193.49z"
      />
      <circle cx="470" cy="132.5" r="7.5" />
      <circle cx="450" cy="427.5" r="7.5" />
      <circle cx="395" cy="42.5" r="7.5" />
      <path d="M192.5,255c5.53,0,10,4.48,10,10s-4.47,10-10,10c-5.52,0-10-4.48-10-10S186.98,255,192.5,255z" />
      <path d="M162.5,255c5.53,0,10,4.48,10,10s-4.47,10-10,10c-5.52,0-10-4.48-10-10S156.98,255,162.5,255z" />
      <path d="M132.5,255c5.53,0,10,4.48,10,10s-4.47,10-10,10c-5.52,0-10-4.48-10-10S126.98,255,132.5,255z" />
      <circle cx="130" cy="57.5" r="7.5" />
      <path d="M102.5,255c5.53,0,10,4.48,10,10s-4.47,10-10,10c-5.52,0-10-4.48-10-10S96.98,255,102.5,255z" />
      <circle cx="65" cy="452.5" r="7.5" />
      <circle cx="45" cy="152.5" r="7.5" />
      <path d="M352.5,490h-200c-2.761,0-5-2.238-5-5V335c0-2.762,2.239-5,5-5c2.762,0,5,2.238,5,5v145h190V335c0-2.762,2.239-5,5-5 c2.762,0,5,2.238,5,5v150C357.5,487.762,355.262,490,352.5,490z" />
      <path d="M392.5,420c-2.761,0-5-2.238-5-5v-75h-270v75c0,2.762-2.238,5-5,5c-2.761,0-5-2.238-5-5v-80c0-2.762,2.239-5,5-5h280 c2.762,0,5,2.238,5,5v80C397.5,417.762,395.262,420,392.5,420z" />
      <path d="M442.5,230h-380c-2.761,0-5-2.239-5-5s2.239-5,5-5h380c2.762,0,5,2.239,5,5S445.262,230,442.5,230z" />
      <path d="M397.5,290c-11.027,0-20-8.972-20-20s8.973-20,20-20c11.028,0,20,8.972,20,20S408.528,290,397.5,290z M397.5,260 c-5.514,0-10,4.486-10,10s4.486,10,10,10c5.515,0,10-4.486,10-10S403.015,260,397.5,260z" />
      <path d="M347.5,290c-11.027,0-20-8.972-20-20s8.973-20,20-20c11.028,0,20,8.972,20,20S358.528,290,347.5,290z M347.5,260 c-5.514,0-10,4.486-10,10s4.486,10,10,10c5.515,0,10-4.486,10-10S353.015,260,347.5,260z" />
      <path d="M423.548,182.611c-0.67,0-1.35-0.135-2.002-0.42C418.223,180.737,414.675,180,411,180h-13.529c-2.762,0-5-2.239-5-5 s2.238-5,5-5H411c5.063,0,9.959,1.019,14.554,3.029c2.53,1.107,3.684,4.055,2.577,6.585 C427.31,181.491,425.474,182.611,423.548,182.611z" />
      <path d="M371.33,180h-28.069c-2.762,0-5-2.239-5-5s2.238-5,5-5h28.069c2.762,0,5,2.239,5,5S374.092,180,371.33,180z" />
      <path d="M352.5,123.645c-2.761,0-5-2.234-5-4.995V91.04c0-2.761,2.239-5,5-5c2.762,0,5,2.239,5,5v27.6 C357.5,121.401,355.262,123.645,352.5,123.645z" />
      <path d="M352.5,180c-2.761,0-5-2.239-5-5v-26c0-2.761,2.239-5,5-5c2.762,0,5,2.239,5,5v26C357.5,177.761,355.262,180,352.5,180z" />
      <path d="M152.5,180c-2.761,0-5-2.239-5-5V35c0-2.761,2.239-5,5-5h200c2.762,0,5,2.239,5,5v29.61c0,2.761-2.238,5-5,5 c-2.761,0-5-2.239-5-5V40h-190v135C157.5,177.761,155.262,180,152.5,180z" />
      <path d="M340.66,213.62h-0.36c-7.625,0-14.341-5.056-16.331-12.295c-2.547-9.268-9.892-16.615-19.169-19.175 c-2.362-0.655-4.487-1.782-6.32-3.351c-3.791-3.2-5.979-7.938-5.979-12.979v-0.36c0-9.382,7.604-17.015,16.949-17.015 c1.516,0,3.033,0.207,4.511,0.615c13.286,3.661,25.12,11.825,33.32,22.99c4.479,6.057,7.773,12.828,9.78,20.112 c1.414,5.132,0.372,10.508-2.859,14.75C350.954,211.175,346.019,213.62,340.66,213.62z M309.449,158.445 c-3.832,0-6.949,3.147-6.949,7.015v0.36c0,2.089,0.896,4.042,2.456,5.359c0.738,0.632,1.574,1.073,2.51,1.333 c12.649,3.491,22.671,13.517,26.146,26.164c0.801,2.911,3.551,4.945,6.688,4.945h0.36c2.21,0,4.246-1.009,5.586-2.768 c1.324-1.738,1.752-3.937,1.174-6.034c-1.68-6.093-4.435-11.758-8.189-16.835c-6.885-9.373-16.8-16.216-27.929-19.283 C310.688,158.531,310.065,158.445,309.449,158.445z" />
      <path d="M390.19,213.62h-0.351c-8.519,0-15.762-6.354-16.849-14.78c-0.971-7.563-3.059-14.882-6.207-21.758 c-4.217-9.267-10.161-17.475-17.675-24.407c-11.668-10.777-26.13-17.536-41.824-19.545c-8.428-1.074-14.785-8.314-14.785-16.839 v-0.36c0-9.374,7.611-17,16.966-17c0.725,0,1.458,0.047,2.181,0.14c15.502,1.978,30.128,7.128,43.467,15.307 c22.057,13.506,38.734,34.357,46.986,58.734c0.04,0.097,0.076,0.195,0.11,0.294c2.293,6.817,3.921,13.906,4.84,21.068 c0.621,4.838-0.871,9.713-4.094,13.376C399.729,211.517,395.077,213.62,390.19,213.62z M309.466,108.931 c-3.841,0-6.966,3.14-6.966,7v0.36c0,3.505,2.603,6.48,6.053,6.92c17.77,2.276,34.139,9.924,47.34,22.117 c8.496,7.839,15.223,17.126,19.989,27.602c3.563,7.782,5.929,16.074,7.028,24.634c0.444,3.452,3.425,6.057,6.93,6.057h0.351 c2.043,0,3.91-0.844,5.258-2.375c1.344-1.528,1.941-3.48,1.683-5.498c-0.822-6.414-2.27-12.759-4.304-18.867 c-0.039-0.095-0.074-0.191-0.107-0.29c-7.471-22.282-22.682-41.347-42.83-53.685c-12.128-7.436-25.421-12.117-39.512-13.915 C310.071,108.95,309.766,108.931,309.466,108.931z" />
      <path d="M439.12,213.62h-0.35c-8.896,0-16.175-6.731-16.933-15.657c-0.55-6.466-1.607-12.913-3.144-19.16 c-9.017-36.787-33.917-67.124-68.314-83.235c-13.335-6.248-27.535-10.042-42.209-11.275c-8.936-0.758-15.671-8.042-15.671-16.942 V67c0.001-9.845,8.398-17.767,18.364-16.943c14.887,1.235,29.495,4.56,43.421,9.882c0.099,0.038,0.195,0.078,0.291,0.122 c27.571,10.594,51.635,28.665,69.608,52.277c18.347,24.1,29.37,52.772,31.879,82.917c0.651,7.884-4.302,15.211-11.816,17.57 c-0.116,0.043-0.234,0.083-0.354,0.117C442.341,213.392,440.735,213.62,439.12,213.62z M309.447,59.998 c-3.831,0-6.947,3.141-6.947,7.002v0.35c0,3.663,2.8,6.663,6.513,6.978c15.854,1.333,31.2,5.433,45.609,12.184 c37.15,17.4,64.044,50.17,73.784,89.907c1.658,6.748,2.802,13.712,3.396,20.697c0.315,3.708,3.311,6.504,6.969,6.504h0.35 c0.593,0,1.184-0.074,1.758-0.22c0.075-0.026,0.152-0.05,0.229-0.073c3.162-0.917,5.261-3.964,4.99-7.246 c-4.718-56.689-42.157-106.459-95.382-126.791c-0.096-0.037-0.19-0.076-0.282-0.118c-12.96-4.923-26.55-8.001-40.396-9.15 C309.839,60.006,309.642,59.998,309.447,59.998z" />
      <path d="M457.5,90c-13.785,0-25-11.215-25-25s11.215-25,25-25s25,11.215,25,25S471.285,90,457.5,90z M457.5,50 c-8.271,0-15,6.729-15,15s6.729,15,15,15c8.271,0,15-6.729,15-15S465.771,50,457.5,50z" />
      <path d="M72.5,125c-19.299,0-35-15.701-35-35s15.701-35,35-35s35,15.701,35,35S91.799,125,72.5,125z M72.5,65 c-13.785,0-25,11.215-25,25s11.215,25,25,25s25-11.215,25-25S86.285,65,72.5,65z" />
    </svg>
  );
}

export default PreferencesIcon;

import * as actionTypes from '../../actionTypes';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const setItemLoading = (status) => ({
  type: actionTypes.ITEM_LOADING,
  payload: status,
});

export const getItemInfo = (id) => {
  return (dispatch) => {
    dispatch(setItemLoading(true));
    http
      .get(`${API_URL}/stores/${id}/items?limit=1000&page=1&type=all`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_ITEMS, payload: res.data});
        dispatch(setItemLoading(false));
      })
      .catch(() => {
        dispatch(setItemLoading(false));
      });
  };
};

export const getCategoryInfo = () => {
  return (dispatch, getState) => {
    const id = getState().auth.restData.store_id;
    dispatch(setItemLoading(true));
    http
      .get(`${API_URL}/stores/${id}/category`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_CATEGORY, payload: res.data});
        dispatch(setItemLoading(false));
      })
      .catch(() => {
        dispatch(setItemLoading(false));
      });
  };
};

export const setCurrentCategory = (category) => ({
  type: actionTypes.HOME_CURRENT_CATEGORY,
  payload: category,
});

export const updateSelectedCategory = (category) => ({
  type: actionTypes.UPDATE_SELECTED_CATEGORY,
  payload: category,
});

export const updateSelectedSubCategory = (subCategory) => ({
  type: actionTypes.UPDATE_SELECTED_SUB_CATEGORY,
  payload: subCategory,
});

export const updateAvailability = (source, data, type, successCB) => {
  return (dispatch) => {
    dispatch(setItemLoading(true));
    http
      .post(`${API_URL}/availability?type=${type}&id=${source._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        const item = {...source};
        item['availability'] = res.data;
        dispatch({type: actionTypes.UPDATE_ITEMS, payload: item});
        dispatch(setItemLoading(false));
      })
      .catch(() => {
        dispatch(setItemLoading(false));
        toast.error('unable to update availability');
      });
  };
};

export const updateLocalAddon = (id, addon) => {
  return (dispatch) => {
    dispatch(setItemLoading(true));
    http
      .put(`${API_URL}/items/${id}/addons/${addon._id}`, addon)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_ITEMS, payload: res.data});
        dispatch(setItemLoading(false));
      })
      .catch(() => {
        dispatch(setItemLoading(false));
        toast.error('Unable to update');
      });
  };
};

export const updateGlobalAddon = (addon) => {
  return (dispatch, getState) => {
    dispatch(setItemLoading(true));
    const restID = getState().auth.restaurant._id;
    http
      .post(`${API_URL}/addons/${addon._id}`, addon)
      .then(async () => {
        await dispatch(setItemLoading(false));
        await dispatch(getCategoryInfo());
        await dispatch(getItemInfo(restID));
      })
      .catch(() => {
        dispatch(setItemLoading(false));
        toast.error('unable to update');
      });
  };
};

import React from 'react';

function QMSIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#455a64"
        d="M46,33.958c0,0-2.215-5.03-8-5.03c-5.786,0-8,5.03-8,5.03L30,37h16L46,33.958z"
      />
      <path
        fill="#ffa726"
        d="M38 27c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4S35.791 27 38 27M27 25c-2.763 0-5-2.238-5-5s2.237-5 5-5c2.761 0 5 2.238 5 5S29.761 25 27 25"
      />
      <path
        fill="#546e7a"
        d="M17,33.197c0,0,2.768-6.287,10-6.287c7.231,0,10,6.287,10,6.287L37,37H17L17,33.197z"
      />
      <path
        fill="#ffa726"
        d="M20,17c0,3.315-2.686,6-6,6c-3.315,0-6-2.685-6-6s2.685-6,6-6C17.313,11,20,13.686,20,17"
      />
      <path
        fill="#78909c"
        d="M26,32.545c0,0-3.321-7.545-12-7.545c-8.68,0-12,7.545-12,7.545L2,37h24L26,32.545z"
      />
      <path
        fill="#ffa726"
        d="M27,25c-2.763,0-5-2.238-5-5s2.237-5,5-5c2.761,0,5,2.238,5,5S29.761,25,27,25"
      />
    </svg>
  );
}

export default QMSIcon;

import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import miscReducers from './miscReducers';
import prefReducers from './prefReducers';
import itemReducers from './itemReducers';
import dineReducers from './dineReducers';
import holdReducers from './holdReducers';
import orderReducers from './orderReducers';
import salesReducers from './salesReducers';
import qmsReducers from './qmsReducers';
import jukeboxReducers from './jukeboxReducers';
import expensesReducers from './expensesReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    misc: miscReducers,
    pref: prefReducers,
    item: itemReducers,
    dine: dineReducers,
    hold: holdReducers,
    orders: orderReducers,
    sales: salesReducers,
    qms: qmsReducers,
    jukebox: jukeboxReducers,
    expenses: expensesReducers,
  });

export default reducers;

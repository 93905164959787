import * as actionTypes from '../../actionTypes';

const initialState = {
  loading: false,
  type: 'dinein',
  typeIndex: 0,
  showDishes: false,
  printJob: [],
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SELECTED_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case actionTypes.TYPE_INDEX:
      return {
        ...state,
        typeIndex: action.payload,
      };
    case actionTypes.SET_SHOW_DISHES:
      return {
        ...state,
        showDishes: action.payload,
      };
    case actionTypes.SET_PRINT_JOB:
      return {
        ...state,
        printJob: [...state.printJob, action.payload],
      };
    case actionTypes.REMOVE_PRINT_JOB:
      const temp = [...state.printJob];
      const jobIndex = temp.findIndex((a) => action.payload);
      if (jobIndex > -1) {
        temp.splice(jobIndex, 1);
      }
      return {
        ...state,
        printJob: temp,
      };
    case actionTypes.RESET_MISC:
      return {
        loading: false,
        type: 'dinein',
        typeIndex: 0,
        showDishes: false,
        printJob: [],
      };
    case actionTypes.LOG_OUT:
      return {
        loading: false,
        type: 'dinein',
        typeIndex: 0,
        showDishes: false,
        printJob: [],
      };
    default:
      return state;
  }
}

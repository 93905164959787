import React, {useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  attendService,
  capturePayment,
  resetNotification,
  cancelOrder,
  settleOrder,
  acknowledgeOrder,
  setTypeIndex,
  lastMiletoSelf,
  acknowledgeUPOrder,
  ackUPCancel,
  setType,
  cancelMultiDropOrder,
  retryMutiDropDeliverySupport,
} from '../../services/actions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useStyles} from './styles';
import {useTranslation} from 'react-i18next';
import CancelDialog from './component/CancelDialog';

function Notification({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [cancelOrderDialog, setCancelOrderDialog] = useState(false);
  const [cancelOrderFor, setCancelOrderFor] = useState('');

  const language = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  const serviceList = useSelector((state) => {
    const temp = [];
    if (state.orders.serviceList.length > 0) {
      state.orders.serviceList.forEach((service) => {
        !service.attended && temp.push(service);
      });
    }
    return temp;
  });
  const dineinPaymentList = useSelector((state) => {
    const temp = [];
    if (state.orders.dineinOrders.length > 0) {
      state.orders.dineinOrders.forEach((dinein) => {
        if (
          dinein.curr_state === 'payment-initalized' &&
          dinein.payment &&
          dinein.payment.mode &&
          dinein.payment.mode !== 'online'
        ) {
          temp.push({
            _id: dinein._id,
            tableNO: dinein.tables[0].table_no,
            total: dinein.bill.round_off_total_amount,
            mode: dinein.payment && dinein.payment.mode,
            paid: dinein.paid,
          });
        }
      });
    }
    return temp;
  });
  const onlineDineinList = useSelector((state) => {
    const temp = [];
    if (state.orders.dineinOrders.length > 0) {
      state.orders.dineinOrders.forEach((dinein) => {
        if (
          dinein.curr_state === 'payment-captured' &&
          dinein.payment &&
          dinein.payment.mode === 'online'
        ) {
          temp.push({
            _id: dinein._id,
            tableNO: dinein.tables[0].table_no,
            total: dinein.bill.round_off_total_amount,
            mode: dinein.payment && dinein.payment.mode,
            paid: dinein.paid,
          });
        }
      });
    }
    return temp;
  });
  const paymentCaptureList = useSelector((state) => {
    const temp = [];
    if (state.orders.takeawayOrders.length > 0) {
      state.orders.takeawayOrders.forEach((takeaway) => {
        if (
          takeaway.curr_state === 'payment-initalized' &&
          takeaway.initialized_by === 'user'
        ) {
          temp.push({
            _id: takeaway._id,
            type: takeaway.__type,
            orderNo: takeaway.order_number,
            total: takeaway.bill.round_off_total_amount,
            mode: takeaway.payment && takeaway.payment.mode,
          });
        }
      });
    }
    if (state.orders.hotelOrders.length > 0) {
      state.orders.hotelOrders.forEach((hotel) => {
        if (
          hotel.curr_state === 'payment-initalized' &&
          hotel.initialized_by === 'user'
        ) {
          temp.push({
            _id: hotel._id,
            type: hotel.__type,
            orderNo: hotel.order_number,
            total: hotel.bill.round_off_total_amount,
            mode: hotel.payment && hotel.payment.mode,
          });
        }
      });
    }
    if (state.orders.qsrOrders.length > 0) {
      state.orders.qsrOrders.forEach((qsr) => {
        if (
          qsr.curr_state === 'payment-initalized' &&
          qsr.initialized_by === 'user'
        ) {
          temp.push({
            _id: qsr._id,
            type: qsr.__type,
            orderNo: qsr.order_number,
            total: qsr.bill.round_off_total_amount,
            mode: qsr.payment && qsr.payment.mode,
          });
        }
      });
    }
    return temp;
  });

  const deliveryNPOrders = useSelector((state) => {
    const temp = [];
    if (state.orders.deliveryNPOrders.length > 0) {
      state.orders.deliveryNPOrders.forEach((delivery) => {
        temp.push({
          _id: delivery._id,
          items: delivery.bill.kots[0].items,
        });
      });
    }
    return temp;
  });
  const deliveryMultiDropNPOrders = useSelector((state) => {
    const temp = [];
    if (
      state.orders.deliveryMultiDropNPOrders &&
      state.orders.deliveryMultiDropNPOrders.length > 0
    ) {
      state.orders.deliveryMultiDropNPOrders.forEach((delivery) => {
        temp.push({
          _id: delivery._id,
          order_number: delivery.order_group_number,
        });
      });
    }
    return temp;
  });
  const deliveryAcceptList = useSelector((state) => {
    const temp = [];
    if (state.orders.deliveryOrders.length > 0) {
      state.orders.deliveryOrders.forEach((delivery) => {
        if (delivery.next_states[0] === 'acknowledged') {
          temp.push({
            _id: delivery._id,
            items: delivery.bill.kots[0].items,
            type: delivery.__type,
            scheduled_delivery_time: delivery.scheduled_delivery_time
              ? delivery.scheduled_delivery_time
              : null,
          });
        }
      });
    }
    return temp;
  });

  const onlineAcceptList = useSelector((state) => {
    const temp = [];
    if (state.orders.onlineOrders.length > 0) {
      state.orders.onlineOrders.forEach((online) => {
        if (online.next_states[0] === 'Acknowledged') {
          temp.push({
            _id: online._id,
            items: online.bill.kots[0].items,
            type: online.__type,
            channelID:
              online.up_details &&
              online.up_details.details &&
              online.up_details.details.aggregator_order_id,
          });
        }
      });
    }
    return temp;
  });
  const onlineCancelList = useSelector((state) => {
    const temp = [];
    if (state.orders.onlineOrders.length > 0) {
      state.orders.onlineOrders.forEach((online) => {
        if (online.canceled) {
          temp.push({
            _id: online._id,
            reason: online.canceled_reason,
            items: online.bill.kots[0].items,
            type: online.__type,
            orderNo: online.order_number,
          });
        }
      });
    }
    return temp;
  });
  const newDinein = useSelector((state) => state.orders.newDinein);
  const newTakeaway = useSelector((state) => state.orders.newTakeaway);
  const newHotel = useSelector((state) => state.orders.newHotel);
  const newQSR = useSelector((state) => state.orders.newQSR);

  const notificationUI = () => {
    const data = [
      {
        index: 0,
        active: newDinein,
        type: 'dinein',
        msg: t('new/update for dine in arrived'),
      },
      {
        index: 1,
        active: newTakeaway,
        type: 'takeaway',
        msg: t('new takeaway order arrived'),
      },
      {
        index: 3,
        active: newHotel,
        type: 'hotel',
        msg: t('new hotel order arrived'),
      },
      {
        index: 4,
        active: newQSR,
        type: 'qsr',
        msg: t('new QSR order arrived'),
      },
    ];
    return (
      <div className={classes.notificationUIRoot}>
        <div className={classes.notificationTitle}>
          <IconButton className={classes.iconButton}>
            <CloseIcon color={'primary'} onClick={handleClose} />
          </IconButton>
          <div>{t('Notifications')}</div>
        </div>
        {data.map(
          (newOrder) =>
            newOrder.active && (
              <div className={classes.queueStyle}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(setTypeIndex(newOrder.index));
                    dispatch(setType(newOrder.type));
                    dispatch(resetNotification(newOrder.type));
                    handleClose();
                  }}
                >
                  {newOrder.msg}
                </Button>
              </div>
            )
        )}
        {serviceList.length > 0 &&
          serviceList.map((service, index) => (
            <div className={classes.queueStyle} key={index}>
              <div className={classes.flexBox}>
                <div
                  style={{
                    width: '80px',
                  }}
                >
                  <Typography
                    style={{fontSize: 36, fontWeight: 600, textAlign: 'center'}}
                  >
                    {service && service.table_id && service.table_id.table_no}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('Table No')}
                  </Typography>
                </div>
                <div
                  style={{
                    width: '250px',
                    borderLeft: '1px solid lightgray',
                    paddingLeft: '10px',
                  }}
                >
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Service Required')}</strong>
                  </Typography>
                  <div style={{textAlign: 'center'}}>
                    {service && service.service && service.service.name}
                  </div>
                  <div className={classes.flexBox}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      style={{marginTop: 10}}
                      onClick={() => {
                        dispatch(attendService(service._id));
                        handleClose();
                      }}
                    >
                      {t('OK')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {deliveryMultiDropNPOrders &&
          deliveryMultiDropNPOrders.length > 0 &&
          deliveryMultiDropNPOrders.map((order, index) => (
            <div
              className={classes.queueStyle}
              key={index}
              style={{
                maxWidth: '250px',
                textAlign: 'center',
              }}
            >
              <div style={{margin: 8, fontWeight: 600, fontSize: 18}}>
                {order.order_number}
              </div>
              <div>
                <strong>All things come for those who wait!</strong>
              </div>
              <div
                style={{
                  marginTop: 6,
                  fontSize: 13,
                }}
              >
                No runners available near you. But if you try after 15mins, you
                might find a runner and can have a happy customer.
              </div>
              <Button
                style={{fontSize: 12, marginTop: 12}}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(retryMutiDropDeliverySupport(order._id));
                  handleClose();
                }}
              >
                Retry
              </Button>
              <Button
                style={{marginTop: 12, fontSize: 12}}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(
                    cancelMultiDropOrder(
                      order._id,
                      'Delivery support not available at this moment'
                    )
                  );
                  handleClose();
                }}
              >
                {t('Cancel')}
              </Button>
            </div>
          ))}
        {deliveryNPOrders &&
          deliveryNPOrders.length > 0 &&
          deliveryNPOrders.map((order, index) => (
            <div
              className={classes.queueStyle}
              key={index}
              style={{
                maxWidth: '250px',
                textAlign: 'center',
              }}
            >
              <strong>All things come for those who wait!</strong>
              <div
                style={{
                  marginTop: 6,
                  fontSize: 13,
                }}
              >
                No runners available near you. But if you try after 15mins, you
                might find a runner and can have a happy customer.
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 5,
                    borderBottom: '1px solid lightgray',
                    textTransform: 'uppercase',
                  }}
                >
                  <div>{t('item')}</div>
                  <div>{t('quantity')}</div>
                </div>
                {order.items &&
                  order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
              </div>
              <Button
                style={{fontSize: 12, marginTop: 12}}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(lastMiletoSelf(order._id));
                  handleClose();
                }}
              >
                Wait and delight your customer
              </Button>
              <Button
                style={{marginTop: 12, fontSize: 12}}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(
                    cancelOrder(
                      order._id,
                      'Delivery support not available at this moment'
                    )
                  );
                  handleClose();
                }}
              >
                {t('Cancel')}
              </Button>
            </div>
          ))}
        {dineinPaymentList.length > 0 &&
          dineinPaymentList.map((queue, index) => (
            <div className={classes.queueStyle} key={index}>
              <div className={classes.flexBox}>
                <div
                  style={{
                    width: '80px',
                    marginRight: '10px',
                    borderRight: '1px solid lightgray',
                  }}
                >
                  <Typography
                    style={{fontSize: 36, fontWeight: 600, textAlign: 'center'}}
                  >
                    {queue.tableNO}
                  </Typography>
                  <Typography style={{fontSize: 12, textAlign: 'center'}}>
                    {t('Table No')}
                  </Typography>
                </div>
                <div style={{width: '250px'}}>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Payment method')} : </strong>
                    <span>{queue.mode}</span>
                  </Typography>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Total')} : </strong>
                    <span>{queue.total}</span>
                  </Typography>
                  <div className={classes.flexBox}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        dispatch(capturePayment(queue._id, false, true));
                        handleClose();
                      }}
                    >
                      {t('Collect and Settle')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {onlineDineinList.length > 0 &&
          onlineDineinList.map((queue, index) => (
            <div className={classes.queueStyle} key={index}>
              <div className={classes.flexBox}>
                <div
                  style={{
                    width: '80px',
                    marginRight: '10px',
                    borderRight: '1px solid lightgray',
                  }}
                >
                  <Typography
                    style={{fontSize: 36, fontWeight: 600, textAlign: 'center'}}
                  >
                    {queue.tableNO}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('Table No')}
                  </Typography>
                </div>
                <div style={{width: '250px'}}>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Payment method')} : </strong>
                    <span>
                      {queue.mode}{' '}
                      <span style={{fontWeight: 600, color: 'green'}}>
                        {queue.paid ? '(PAID)' : ''}
                      </span>
                    </span>
                  </Typography>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Total')} : </strong>
                    <span>{queue.total}</span>
                  </Typography>
                  <div className={classes.flexBox}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        dispatch(settleOrder(queue._id));
                        handleClose();
                      }}
                    >
                      {t('Settle')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {onlineCancelList.length > 0 &&
          onlineCancelList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div style={{width: '250px'}}>
                <Typography
                  style={{fontSize: 16, fontWeight: 600, textAlign: 'center'}}
                >
                  {'Order Number : '}
                  {order.orderNo}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                  }}
                >
                  {'Cancel Reason : '}
                  {order.reason}
                </Typography>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 5,
                      borderBottom: '1px solid lightgray',
                      textTransform: 'uppercase',
                    }}
                  >
                    <div>{t('item')}</div>
                    <div>{t('quantity')}</div>
                  </div>
                  {order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
                </div>
                <div className={classes.flexBox} style={{marginTop: 5}}>
                  <Button
                    style={{marginRight: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(ackUPCancel(order._id));
                      handleClose();
                    }}
                  >
                    {t('OK')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        {paymentCaptureList.length > 0 &&
          paymentCaptureList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div className={classes.flexBox}>
                {/* <div
                  style={{
                    width: '80px',
                    marginRight: '10px',
                    borderRight: '1px solid lightgray',
                  }}
                >
                  <Typography
                    style={{fontSize: 36, fontWeight: 600, textAlign: 'center'}}
                  >
                    {order.orderNo}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('Order No')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {order.type}
                  </Typography>
                </div> */}
                <div style={{width: '350px'}}>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Order No')} : </strong>
                    <span style={{color: 'rgb(253, 42, 42)'}}>
                      {order.orderNo}
                    </span>
                  </Typography>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Payment method')} : </strong>
                    <span>{order.mode}</span>
                  </Typography>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Total')} : </strong>
                    <span>{order.total}</span>
                  </Typography>
                  <div className={classes.flexBox}>
                    <Button
                      style={{marginRight: 5}}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        dispatch(capturePayment(order._id, true));
                        handleClose();
                      }}
                    >
                      {t('Accept')}
                    </Button>
                    <Button
                      style={{marginLeft: 5}}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        dispatch(
                          cancelOrder(
                            order._id,
                            "Store doesn't accept this payment"
                          )
                        );
                        handleClose();
                      }}
                    >
                      {t('Reject')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {deliveryAcceptList.length > 0 &&
          deliveryAcceptList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div style={{width: '250px'}}>
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  <strong>{t('new delivery order arrived')}</strong>
                </Typography>
                {order.scheduled_delivery_time && (
                  <Typography
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      marginTop: 6,
                    }}
                  >
                    <p>
                      Order scheduled @{' '}
                      {new Date(order.scheduled_delivery_time).toLocaleString()}
                    </p>
                  </Typography>
                )}
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 5,
                      borderBottom: '1px solid lightgray',
                      textTransform: 'uppercase',
                    }}
                  >
                    <div>{t('item')}</div>
                    <div>{t('quantity')}</div>
                  </div>
                  {order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
                </div>
                <div className={classes.flexBox} style={{marginTop: 5}}>
                  <Button
                    style={{marginRight: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(acknowledgeOrder(order._id, true));
                      handleClose();
                    }}
                  >
                    {t('Accept')}
                  </Button>
                  <Button
                    style={{marginLeft: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(
                        cancelOrder(
                          order._id,
                          "Store doesn't accept your order"
                        )
                      );
                      handleClose();
                    }}
                  >
                    {t('Reject')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        {onlineAcceptList.length > 0 &&
          onlineAcceptList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div style={{width: '250px'}}>
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  <strong>{'New online order arrived'}</strong>
                  <div>#{order.channelID}</div>
                </Typography>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 5,
                      borderBottom: '1px solid lightgray',
                      textTransform: 'uppercase',
                    }}
                  >
                    <div>{t('item')}</div>
                    <div>{t('quantity')}</div>
                  </div>
                  {order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
                </div>
                <div className={classes.flexBox} style={{marginTop: 5}}>
                  <Button
                    style={{marginRight: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(acknowledgeUPOrder(order._id));
                      handleClose();
                    }}
                  >
                    {t('Accept')}
                  </Button>
                  <Button
                    style={{marginLeft: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      setCancelOrderDialog(true);
                      setCancelOrderFor(order._id);
                    }}
                  >
                    {t('Reject')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        <CancelDialog
          open={cancelOrderDialog}
          orderID={cancelOrderFor}
          handleClose={() => {
            setCancelOrderDialog(false);
            setCancelOrderFor('');
          }}
          urbanPiper
        />
      </div>
    );
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={handleClose}>
      {notificationUI()}
    </Drawer>
  );
}

export default Notification;

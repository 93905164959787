import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStyles} from './styles';
import Typography from '@material-ui/core/Typography';
import {
  printKOTandBillOrder,
  setCurrentSelectedHold,
  setCurrentSelectedOrder,
  setCurrentSelectedTable,
} from '../../../services/actions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BackButton from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from '@material-ui/core';

const UnCapitalizedButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button);

function OrderHeading({
  selectedOrder,
  hide,
  enableCloseOrder,
  orderFor,
  orderForDinein,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [printDialog, setPrintDialog] = useState(false);
  const [printFor, setPrintFor] = useState('');

  const handleClick = () => {
    if (selectedOrder.__type === 'takeaway') {
      setPrintDialog(true);
    } else if (
      selectedOrder.__type !== 'dinein' &&
      selectedOrder.__type !== 'group-delivery'
    ) {
      dispatch(printKOTandBillOrder(selectedOrder));
    } else if (selectedOrder.__type === 'group-delivery') {
      selectedOrder.orders.length > 0 &&
        selectedOrder.orders.forEach((order) => {
          dispatch(printKOTandBillOrder(order));
        });
    }
  };

  const handleBack = () => {
    dispatch(setCurrentSelectedHold({}));
    dispatch(setCurrentSelectedOrder());
    dispatch(setCurrentSelectedTable());
  };

  return (
    <>
      <Toolbar style={{display: hide ? 'none' : 'flex'}}>
        {enableCloseOrder && (
          <div>
            <IconButton onClick={handleBack}>
              <BackButton />
            </IconButton>
          </div>
        )}
        <div style={{flex: 1}}>
          {orderFor || orderForDinein ? (
            <Typography
              variant="h6"
              component="h6"
              className={classes.takeawayOrderNumber}
            >
              {orderForDinein && `${t('Table No')} : `}
              <span
                style={{
                  color: '#FD2A2A',
                  fontSize: 22,
                  textTransform: 'uppercase',
                }}
              >
                {orderFor}
              </span>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              component="h6"
              className={classes.takeawayOrderNumber}
            >
              {selectedOrder &&
                selectedOrder.__type === 'dinein' &&
                t('Table No')}
              &nbsp;
              <span style={{color: '#FD2A2A', fontSize: 22}}>
                {selectedOrder && selectedOrder.__type !== 'dinein'
                  ? selectedOrder.__type === 'group-delivery'
                    ? selectedOrder.order_group_number
                    : selectedOrder.order_number
                  : selectedOrder &&
                    selectedOrder.tables &&
                    selectedOrder.tables.length > 0 &&
                    selectedOrder.tables
                      .map((table) => table.table_no)
                      .toString()}
              </span>
            </Typography>
          )}
        </div>
        {!orderFor && !orderForDinein && (
          <div>
            {selectedOrder && selectedOrder.__type !== 'dinein' ? (
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleClick}
              >
                {t('Print')}
              </Button>
            ) : (
              <>
                <div style={{fontSize: 12, marginRight: 5}}>
                  {t('Group code')}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    letterSpacing: 5,
                    marginBottom: 5,
                  }}
                >
                  {selectedOrder && selectedOrder.enigma}
                </div>
              </>
            )}
          </div>
        )}
      </Toolbar>
      {printDialog && (
        <Dialog open={printDialog} onClose={() => setPrintDialog(false)}>
          <DialogContent>
            <FormControl
              style={{width: 200}}
              variant="outlined"
              fullWidth
              size={'small'}
            >
              <InputLabel id="takeaway-print-for">
                Print this bill/kot for
              </InputLabel>
              <Select
                size={'small'}
                labelId="takeaway-print-for"
                value={printFor}
                onChange={(e) => setPrintFor(e.target.value)}
                label={'Print this bill/kot for'}
              >
                <MenuItem value={'swiggy'}>Swiggy</MenuItem>
                <MenuItem value={'zomato'}>Zomato</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <UnCapitalizedButton
              variant={'outlined'}
              color={'primary'}
              onClick={() => {
                if (printFor) {
                  dispatch(printKOTandBillOrder(selectedOrder, printFor));
                } else {
                  dispatch(printKOTandBillOrder(selectedOrder));
                }
                setPrintDialog(false);
              }}
            >
              Print as Takeaway {printFor ? `- ${printFor}` : ''}
            </UnCapitalizedButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default OrderHeading;

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PrivateQueueAuthHoc({component: Component, ...rest}) {
  const restData = useSelector((state) => state.auth.restData);
  const token = restData.token ? restData.token : '';
  const queueAccess =
    restData.dashboard_access && restData.dashboard_access.includes('qms');
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' && queueAccess ? (
          <Component {...prop} />
        ) : (
          <Redirect to={'/'} />
        )
      }
    />
  );
}

export default PrivateQueueAuthHoc;

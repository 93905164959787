import React, {lazy, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {lightTheme, darkTheme} from './utils/themes/theme';
import './App.scss';
import {Route, Switch} from 'react-router';
import {ToastContainer, toast} from 'react-toastify';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'react-toastify/dist/ReactToastify.css';

import suspenseHoc from './hoc/suspenseHoc';
import PrivateCashierAuthHoc from './hoc/PrivateCashierAuthHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import headerHoc from './hoc/headerHoc';
import Ably from './services/Ably';
import LoadingModal from './modules/LoadingModal';
import OrderSummary from './modules/OrderSummary';
import NotFound from './modules/NotFound';
import PrivateQueueAuthHoc from './hoc/PrivateQueueAuthHoc';
const LandingPage = lazy(() => import('./pages/LandingPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const QMSPage = lazy(() => import('./pages/QMSPage'));
const DashboardView = lazy(() => import('./mobileView/DashboardView'));
const ManageItemsPage = lazy(() => import('./pages/ManageItemsPage'));
const PreferencesPage = lazy(() => import('./pages/PreferencesPage'));
const SalesReportPage = lazy(() => import('./pages/SalesReportPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const TableBookingPage = lazy(() => import('./pages/TableBookingPage'));
const JukeBoxPage = lazy(() => import('./pages/JukeBoxPage'));
const ExpensesPage = lazy(() => import('./pages/ExpensesPage'));
const PaymentPage = lazy(() => import('./pages/PaymentsPage'));

function App() {
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '22.09.27s');
      } else if (appVersion !== '22.09.27s') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '22.09.27s');
          window.location.reload();
        }, 3000);
      }
    };
  }, []);

  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  const matches = useMediaQuery('(min-width:800px)');
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <LoadingModal />
      <Switch>
        <PrivateCashierAuthHoc
          exact
          path="/home"
          component={
            matches
              ? suspenseHoc(headerHoc(DashboardPage))
              : suspenseHoc(headerHoc(DashboardView))
          }
        />
        <PrivateCashierAuthHoc
          exact
          path="/menu"
          component={suspenseHoc(headerHoc(ManageItemsPage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/printer-setup"
          component={suspenseHoc(headerHoc(PreferencesPage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/sales-report"
          component={suspenseHoc(headerHoc(SalesReportPage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/table-reservations"
          component={suspenseHoc(headerHoc(TableBookingPage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/profile"
          component={suspenseHoc(headerHoc(ProfilePage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/jukebox"
          component={suspenseHoc(headerHoc(JukeBoxPage))}
        />
        <PrivateQueueAuthHoc
          exact
          path="/qms"
          component={suspenseHoc(headerHoc(QMSPage))}
        />
        <PrivateCashierAuthHoc
          path="/expenses"
          component={suspenseHoc(headerHoc(ExpensesPage))}
        />
        <PrivateCashierAuthHoc
          path="/payments"
          component={suspenseHoc(headerHoc(PaymentPage))}
        />
        <LandingAuthHoc exact path="/" component={suspenseHoc(LandingPage)} />
        <Route component={NotFound} />
      </Switch>
      <Ably />
      <OrderSummary />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CustomDeliveryIcon() {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.10538 7.84592L5.32162 9.41468C5.32483 9.40811 5.32742 9.4014 5.33063 9.39484L8.15793 3.57697L4.95038 2.01264C4.6597 1.87073 4.3089 1.99158 4.16699 2.28241L1.8356 7.06253C1.69369 7.35337 1.81439 7.70416 2.10538 7.84592Z" />
      <path d="M11.0577 3.60336C12.0512 3.60336 12.8595 2.7951 12.8595 1.80161C12.8595 0.808258 12.0512 0 11.0577 0C10.0644 0 9.2561 0.808258 9.2561 1.80161C9.2561 2.7951 10.0644 3.60336 11.0577 3.60336Z" />
      <path d="M16.2201 12.44C16.1338 12.44 16.0484 12.4432 15.9636 12.4489L14.8881 7.73697C14.8402 7.76718 14.7908 7.79526 14.7397 7.82104L13.8121 8.28888L14.8208 12.709C13.9488 13.0579 13.2357 13.7221 12.8244 14.5605C13.2228 14.7002 13.556 14.9792 13.7654 15.3391C13.9972 14.695 14.4758 14.1681 15.0863 13.8719L15.3599 15.0705C15.0103 15.3328 14.7838 15.7504 14.7838 16.2199C14.7838 17.0119 15.4281 17.6562 16.2201 17.6562C17.012 17.6562 17.6562 17.012 17.6562 16.2201C17.6562 15.5249 17.1597 14.9437 16.5028 14.8119L16.2291 13.6122C17.6629 13.6171 18.8281 14.7849 18.8281 16.2201C18.8281 17.6581 17.658 18.8281 16.2201 18.8281C15.0908 18.8281 14.1272 18.1065 13.7652 17.1004C13.5559 17.4605 13.2228 17.7397 12.8242 17.8793C13.4398 19.1341 14.7307 20 16.2201 20C18.3043 20 20 18.3043 20 16.2201C20 14.1357 18.3043 12.44 16.2201 12.44Z" />
      <path d="M7.1355 11.9209L8.62994 14.9098L9.285 13.5997L8.44562 11.9209H7.1355Z" />
      <path d="M8.28278 15.6342H7.5145C7.35962 14.6426 6.81778 13.7775 6.04889 13.1987L6.69464 11.9075C6.29242 11.8584 5.92453 11.6731 5.64651 11.3834L5.0145 12.6472C4.62738 12.5131 4.21204 12.44 3.77991 12.44C1.69571 12.44 0 14.1357 0 16.2201C0 18.3043 1.69571 20 3.78006 20C5.66498 20 7.23206 18.613 7.5145 16.806H8.28278C8.14789 16.4278 8.14667 16.0162 8.28278 15.6342ZM6.32095 15.6342H5.09079C5.05234 15.5486 5.00595 15.4675 4.95224 15.3918L5.51208 14.2723C5.90912 14.626 6.19751 15.0987 6.32095 15.6342ZM3.78006 18.8281C2.34192 18.8281 1.17188 17.6581 1.17188 16.2201C1.17188 14.782 2.34192 13.6119 3.78006 13.6119C4.02374 13.6119 4.25949 13.6462 4.48349 13.7091L3.9415 14.7931C3.88855 14.7871 3.83453 14.7838 3.78006 14.7838C2.98798 14.7838 2.34375 15.4282 2.34375 16.2201C2.34375 17.012 2.98798 17.6562 3.78006 17.6562C4.36325 17.6562 4.86603 17.3065 5.09079 16.806H6.32095C6.05423 17.9628 5.01663 18.8281 3.78006 18.8281Z" />
      <path d="M16.1583 7.87674V8.60809C16.1583 8.93173 16.4206 9.19403 16.7443 9.19403C17.0679 9.19403 17.3302 8.93173 17.3302 8.60809V7.87674C17.3302 6.83167 16.6011 5.95428 15.625 5.72479C15.7355 6.07361 15.7344 6.4473 15.6176 6.80161C15.6021 6.8483 15.5846 6.89377 15.5657 6.93863C15.9159 7.10602 16.1583 7.46353 16.1583 7.87674Z" />
      <path d="M12.2447 15.6342H10.8881L13.4926 10.4251C13.5834 10.2435 13.5738 10.0278 13.4669 9.85504C13.3601 9.68231 13.1715 9.57718 12.9684 9.57718H7.8479L8.11645 9.02451L9.87244 5.41122V8.01178C9.87244 8.21518 9.97787 8.40408 10.1512 8.51059C10.3244 8.6174 10.5406 8.62656 10.7222 8.53485L13.5599 7.10373L14.2119 6.77475C14.3202 6.72012 14.4043 6.63651 14.4594 6.53793C14.4962 6.47232 14.52 6.39999 14.5294 6.32538C14.5319 6.30661 14.5332 6.28784 14.5338 6.26892C14.5345 6.25015 14.5341 6.23108 14.5328 6.21216C14.5277 6.13617 14.5076 6.06003 14.4713 5.9877C14.3256 5.69885 13.9731 5.58289 13.6842 5.72845L12.7032 6.2233L11.0443 7.05994V4.77493C10.3459 4.77173 9.70398 4.52637 9.19754 4.11896L7.23816 8.15079L6.38458 9.90692C6.29639 10.0887 6.30783 10.3029 6.41495 10.4739C6.52206 10.6451 6.70975 10.7491 6.91162 10.7491H12.0204L9.41605 15.9579C9.32526 16.1397 9.33487 16.3553 9.44168 16.528C9.54834 16.7007 9.73709 16.806 9.94003 16.806H12.2447C12.5684 16.806 12.8307 16.5436 12.8307 16.2201C12.8307 15.8965 12.5684 15.6342 12.2447 15.6342Z" />
      <path d="M0.586304 1.97983H2.73215C3.05579 1.97983 3.31808 1.71753 3.31808 1.39389C3.31808 1.07025 3.05579 0.807953 2.73215 0.807953H0.586304C0.262665 0.807953 0.000366211 1.07025 0.000366211 1.39389C0.000366211 1.71753 0.262665 1.97983 0.586304 1.97983Z" />
      <path d="M0.586304 4.30359H1.81036C2.134 4.30359 2.3963 4.04129 2.3963 3.71765C2.3963 3.39417 2.134 3.13171 1.81036 3.13171H0.586304C0.262665 3.13171 0.000366211 3.39417 0.000366211 3.71765C0.000366211 4.04129 0.262665 4.30359 0.586304 4.30359Z" />
    </SvgIcon>
  );
}

export default CustomDeliveryIcon;

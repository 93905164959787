import axios from 'axios';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {push} from 'connected-react-router';
import {setLoading, fetchSource} from '../miscActions';
import {http} from '../../http';

export const login = (user, pwd) => {
  return (dispatch) => {
    dispatch({type: actionTypes.API_LOADING, payload: true});
    axios
      .post(`${API_URL}/store_emp/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        if (
          res.data.dashboard_access &&
          (res.data.dashboard_access.includes('cashier') ||
            res.data.dashboard_access.includes('qms'))
        ) {
          await window.localStorage.setItem('auth_token', res.data.token);
          await window.localStorage.setItem(
            'refresh_token',
            res.data.refreshToken
          );
          await dispatch({type: actionTypes.REST_DATA, payload: res.data});
          if (res.data.dashboard_access.includes('cashier')) {
            await dispatch(push('/home'));
          } else if (res.data.dashboard_access.includes('qms')) {
            await dispatch(push('/qms'));
          }
          await dispatch(
            fetchSource(res.data.store_id, res.data.dashboard_access)
          );
        } else {
          dispatch({type: actionTypes.API_LOADING, payload: false});
          dispatch(loginError(true));
        }
        await dispatch({type: actionTypes.API_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.API_LOADING, payload: false});
        dispatch(loginError(true));
      });
  };
};

export const getRestData = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RESTAURANT_DETAILS,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const loginError = (value) => ({
  type: actionTypes.LOGIN_ERROR,
  payload: value,
});

export const getWalletDetails = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/wallet/details`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_WALLET_DETAILS,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addMoneyToWallet = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/wallet/recharge`, data)
      .then(async (res) => {
        // console.log('res.data', res.data);
        // if (res.data && res.data.payment && res.data.payment.payment_link) {
        //   const strWindowFeatures =
        //     'location=yes,height=900,width=600,scrollbars=yes,status=yes';
        //   window.open(
        //     res.data.payment.payment_link,
        //     '_blank',
        //     strWindowFeatures
        //   );
        // }
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    await dispatch(setLoading(true));
    await dispatch({type: actionTypes.LOG_OUT});
    // window.localStorage.clear();
    // window.location.reload();
  };
};

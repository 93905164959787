import * as actionTypes from '../../actionTypes';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import {API_URL} from '../../../utils/constants';
import {saveOrders} from '../orderActions';

export const getTableInfo = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/tables/${id}?limit=1000&page=1`)
      .then((res) => {
        dispatch(initTable(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getWaiterDetails = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/store_emp?role=waiter`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_WAITER,
          payload: res.data.result,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getBookedTables = (date) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID =
      getState().auth.restData && getState().auth.restData.store_id;
    http
      .get(`${API_URL}/table/booking/${storeID}?date=${date}`)
      .then((res) => {
        dispatch({
          type: actionTypes.BOOKED_TABLE,
          payload: res.data,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const removeBookedTable = (bookingId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/table/booking/${bookingId}`)
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_BOOKED_TABLE,
          payload: bookingId,
        });
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const initTable = (tables) => ({
  type: actionTypes.INIT_TABLE,
  payload: tables,
});

export const updateTableCurrentOrder = (tableID, orderID) => ({
  type: actionTypes.UPDATE_TABLE_CURRENT_ORDER,
  payload: {table_id: tableID, current_order: orderID},
});

export const setCurrentSelectedTable = (table) => ({
  type: actionTypes.CURRENT_SELECTED_TABLE,
  payload: table,
});

export const createDineinQueue = (tableID, tableNO, type) => ({
  type: actionTypes.CREATE_DINEIN_QUEUE,
  payload: {type: type, items: [], table_no: tableNO, table_id: tableID},
});

export const addItemToDineInQueue = (tableID, item) => ({
  type: actionTypes.ADD_ITEM_TO_DINEIN_QUEUE,
  payload: {table_id: tableID, item: item},
});

export const incrementDineInQueueItem = (tableID, item) => ({
  type: actionTypes.INCREMENT_ITEM_DINEIN_QUEUE,
  payload: {table_id: tableID, item: item},
});

export const complimentDineInQueueItem = (tableID, item, reason) => ({
  type: actionTypes.COMPLIMENT_ITEM_DINEIN_QUEUE,
  payload: {table_id: tableID, item: item, reason: reason},
});

export const updateNoteDineInQueueItem = (tableID, item, value) => ({
  type: actionTypes.UPDATE_NOTE_DINEIN_QUEUE,
  payload: {table_id: tableID, item: item, value: value},
});

export const decrementDineInQueueItem = (tableID, item) => ({
  type: actionTypes.DECREMENT_ITEM_DINEIN_QUEUE,
  payload: {table_id: tableID, item: item},
});

export const removeItemFromDineInQueue = (tableID, item) => ({
  type: actionTypes.REMOVE_ITEM_FROM_DINEIN_QUEUE,
  payload: {table_id: tableID, item: item},
});

export const removeDineInQueue = (tableID) => ({
  type: actionTypes.REMOVE_DINEIN_QUEUE,
  payload: tableID,
});

export const transferTable = (orderID, type, from, to) => {
  return (dispatch) => {
    const data = {};
    if (from) {
      data['from'] = from;
    }
    data['type'] = type;
    data['to'] = to;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/transfer_table`, data)
      .then((res) => {
        dispatch(saveOrders(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

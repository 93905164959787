import moment from 'moment';

export const API_URL = process.env.REACT_APP_API_URL;
export const ABLY_KEY = process.env.REACT_APP_ABLY_KEY;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const MAP_KEY = process.env.REACT_APP_GMAP_KEY;

export const POS_PRINT_URL = 'http://localhost:26502';

export const getDateFormat = (from, to) => {
  let temp = '';
  if (moment(from).isSame(moment(to), 'date')) {
    if (moment().isSame(moment(from), 'date')) {
      temp = `Today ${moment(to).format('MMM DD, YYYY')}`;
    } else if (moment().subtract(1, 'day').isSame(moment(from), 'date')) {
      temp = `Yesterday ${moment(to).format('MMM DD, YYYY')}`;
    } else {
      temp = `${moment(from).format('MMM DD, YYYY')}`;
    }
  } else if (
    moment(from).isSame(moment().subtract(7, 'days'), 'date') &&
    moment(to).isSame(moment(), 'date')
  ) {
    temp = `Last week ${moment(from).format('MMM DD')} - ${moment(to).format(
      'MMM DD, YYYY'
    )}`;
  } else {
    temp = `${moment(from).format('MMM DD')} - ${moment(to).format(
      'MMM DD, YYYY'
    )}`;
  }
  return temp;
};

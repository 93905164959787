import * as actionTypes from '../../actionTypes';

const initialState = {
  orderLoading: false,
  currentSelectedOrder: {_id: '', type: ''},
  dineinOrders: [],
  takeawayOrders: [],
  deliveryOrders: [],
  multiDropOrders: [],
  hotelOrders: [],
  qsrOrders: [],
  deliveryNPOrders: [],
  deliveryMultiDropNPOrders: [],
  newDinein: false,
  newTakeaway: false,
  newHotel: false,
  newQSR: false,
  serviceList: [],
  onlineOrders: [],
  storeLoyalty: [],
};

export default function orderReducers(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_SELECTED_ORDER': {
      return {
        ...state,
        currentSelectedOrder: action.payload,
      };
    }
    case 'ORDER_LOADING':
      return {
        ...state,
        orderLoading: action.payload,
      };
    case 'INIT_SERVICE_LIST': {
      return {
        ...state,
        serviceList: action.payload,
      };
    }
    case 'INIT_STORE_LOYALTY': {
      return {
        ...state,
        storeLoyalty: action.payload,
      };
    }
    case 'REMOVE_SERVICE_FROM_LIST': {
      const removeServiceList = [...state.serviceList];
      const removeServiceIndex = removeServiceList.findIndex(
        (a) => a._id === action.payload
      );
      if (removeServiceIndex > -1) {
        removeServiceList.splice(removeServiceIndex, 1);
      }
      return {
        ...state,
        serviceList: removeServiceList,
      };
    }
    case 'ADD_TO_DINEIN_ORDERS': {
      const addDinein = [...state.dineinOrders];
      const addDineinIndex = addDinein.findIndex(
        (a) => a._id === action.payload._id
      );
      let acknowledge = false;
      if (action.payload.next_states[0] === 'acknowledged') {
        acknowledge = true;
      }
      if (addDineinIndex > -1) {
        addDinein[addDineinIndex] = action.payload;
      } else {
        addDinein.push(action.payload);
      }
      return {
        ...state,
        dineinOrders: addDinein,
        newDinein: acknowledge,
      };
    }
    case 'REMOVE_DINEIN_ORDERS': {
      const removeDinein = [...state.dineinOrders];
      const removeDineinIndex = removeDinein.findIndex(
        (a) => a._id === action.payload
      );
      if (removeDineinIndex > -1) {
        removeDinein.splice(removeDineinIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        dineinOrders: removeDinein,
      };
    }
    case 'ADD_TO_TAKEAWAY_ORDERS': {
      const addTakeaway = [...state.takeawayOrders];
      const addTakeawayIndex = addTakeaway.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addTakeawayIndex > -1) {
        addTakeaway[addTakeawayIndex] = action.payload;
      } else {
        addTakeaway.push(action.payload);
      }
      let acknowledge = false;
      if (action.payload.next_states[0] === 'acknowledged') {
        acknowledge = true;
      }
      return {
        ...state,
        takeawayOrders: addTakeaway,
        newTakeaway: acknowledge,
      };
    }
    case 'REMOVE_TAKEAWAY_ORDERS': {
      const removeTakeaway = [...state.takeawayOrders];
      const removeTakeawayIndex = removeTakeaway.findIndex(
        (a) => a._id === action.payload
      );
      if (removeTakeawayIndex > -1) {
        removeTakeaway.splice(removeTakeawayIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        takeawayOrders: removeTakeaway,
      };
    }
    case 'ADD_TO_DELIVERY_ORDERS': {
      const addDelivery = [...state.deliveryOrders];
      const addDeliveryIndex = addDelivery.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addDeliveryIndex > -1) {
        addDelivery[addDeliveryIndex] = action.payload;
      } else {
        addDelivery.push(action.payload);
      }
      return {
        ...state,
        deliveryOrders: addDelivery,
      };
    }
    case 'REMOVE_DELIVERY_ORDERS': {
      const removeDelivery = [...state.deliveryOrders];
      const removeDeliveryIndex = removeDelivery.findIndex(
        (a) => a._id === action.payload
      );
      if (removeDeliveryIndex > -1) {
        removeDelivery.splice(removeDeliveryIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        deliveryOrders: removeDelivery,
      };
    }
    case 'ADD_TO_NOT_POSSIBLE_DELIVERY': {
      const addDeliveryNP = [...state.deliveryNPOrders];
      const addDeliveryNPIndex = addDeliveryNP.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addDeliveryNPIndex > -1) {
        addDeliveryNP[addDeliveryNPIndex] = action.payload;
      } else {
        addDeliveryNP.push(action.payload);
      }
      return {
        ...state,
        deliveryNPOrders: addDeliveryNP,
      };
    }
    case 'ADD_TO_NOT_POSSIBLE_MULTIDROP_DELIVERY': {
      const addmultiDropDeliveryNP = [...state.deliveryMultiDropNPOrders];
      const addmultiDropDeliveryNPIndex = addmultiDropDeliveryNP.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addmultiDropDeliveryNPIndex > -1) {
        addmultiDropDeliveryNP[addmultiDropDeliveryNPIndex] = action.payload;
      } else {
        addmultiDropDeliveryNP.push(action.payload);
      }
      return {
        ...state,
        deliveryMultiDropNPOrders: addmultiDropDeliveryNP,
      };
    }
    case 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY': {
      const removeDeliveryNP = [...state.deliveryNPOrders];
      const removeDeliveryNPIndex = removeDeliveryNP.findIndex(
        (a) => a._id === action.payload
      );
      if (removeDeliveryNPIndex > -1) {
        removeDeliveryNP.splice(removeDeliveryNPIndex, 1);
      }
      return {
        ...state,
        deliveryNPOrders: removeDeliveryNP,
      };
    }
    case 'REMOVE_FROM_NOT_POSSIBLE_MULTIDROP_DELIVERY': {
      const removemultiDropDeliveryNP = [...state.deliveryMultiDropNPOrders];
      const removemultiDropDeliveryNPIndex =
        removemultiDropDeliveryNP.findIndex((a) => a._id === action.payload);
      if (removemultiDropDeliveryNPIndex > -1) {
        removemultiDropDeliveryNP.splice(removemultiDropDeliveryNPIndex, 1);
      }
      return {
        ...state,
        deliveryMultiDropNPOrders: removemultiDropDeliveryNP,
      };
    }
    case 'ADD_TO_MULTIDROP_ORDERS': {
      const addMultiDrop = [...state.multiDropOrders];
      const addMultiDropIndex = addMultiDrop.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addMultiDropIndex > -1) {
        addMultiDrop[addMultiDropIndex] = action.payload;
      } else {
        addMultiDrop.push(action.payload);
      }
      return {
        ...state,
        multiDropOrders: addMultiDrop,
      };
    }
    case 'UPDATE_MULTIDROP_ORDER': {
      const updateMultiDrop = [...state.multiDropOrders];
      let updateMultiDropIndex = -1;
      let updateOrderIndex = -1;
      updateMultiDrop.forEach((a, index) => {
        a.orders.forEach((obj, ind) => {
          if (obj._id === action.payload._id) updateMultiDropIndex = index;
          updateOrderIndex = ind;
        });
      });
      if (updateMultiDropIndex > -1 && updateOrderIndex > -1) {
        updateMultiDrop[updateMultiDropIndex].orders[updateOrderIndex] =
          action.payload;
      }
      return {
        ...state,
        multiDropOrders: updateMultiDrop,
      };
    }
    case 'REMOVE_MULTIDROP_ORDERS': {
      const removeMultiDrop = [...state.multiDropOrders];
      const removeMultiDropIndex = removeMultiDrop.findIndex(
        (a) => a._id === action.payload
      );
      if (removeMultiDropIndex > -1) {
        removeMultiDrop.splice(removeMultiDropIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        multiDropOrders: removeMultiDrop,
      };
    }
    case 'ADD_TO_HOTEL_ORDERS': {
      const addHotel = [...state.hotelOrders];
      const addHotelIndex = addHotel.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addHotelIndex > -1) {
        addHotel[addHotelIndex] = action.payload;
      } else {
        addHotel.push(action.payload);
      }
      let acknowledge = false;
      if (action.payload.next_states[0] === 'acknowledged') {
        acknowledge = true;
      }
      return {
        ...state,
        hotelOrders: addHotel,
        newHotel: acknowledge,
      };
    }
    case 'REMOVE_HOTEL_ORDERS': {
      const removeHotel = [...state.hotelOrders];
      const removeHotelIndex = removeHotel.findIndex(
        (a) => a._id === action.payload
      );
      if (removeHotelIndex > -1) {
        removeHotel.splice(removeHotelIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        hotelOrders: removeHotel,
      };
    }
    case 'ADD_TO_QSR_ORDERS': {
      const addQSR = [...state.qsrOrders];
      const addQSRIndex = addQSR.findIndex((a) => a._id === action.payload._id);
      if (addQSRIndex > -1) {
        addQSR[addQSRIndex] = action.payload;
      } else {
        addQSR.push(action.payload);
      }
      let acknowledge = false;
      if (action.payload.next_states[0] === 'acknowledged') {
        acknowledge = true;
      }
      return {
        ...state,
        qsrOrders: addQSR,
        newQSR: acknowledge,
      };
    }
    case 'REMOVE_QSR_ORDERS': {
      const removeQSR = [...state.qsrOrders];
      const removeQSRIndex = removeQSR.findIndex(
        (a) => a._id === action.payload
      );
      if (removeQSRIndex > -1) {
        removeQSR.splice(removeQSRIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        qsrOrders: removeQSR,
      };
    }
    case 'ADD_TO_ONLINE_ORDERS': {
      const addOnline = [...state.onlineOrders];
      const addOnlineIndex = addOnline.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addOnlineIndex > -1) {
        addOnline[addOnlineIndex] = action.payload;
      } else {
        addOnline.push(action.payload);
      }
      return {
        ...state,
        onlineOrders: addOnline,
      };
    }
    case 'REMOVE_ONLINE_ORDERS': {
      const removeOnline = [...state.onlineOrders];
      const removeOnlineIndex = removeOnline.findIndex(
        (a) => a._id === action.payload
      );
      if (removeOnlineIndex > -1) {
        removeOnline.splice(removeOnlineIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        onlineOrders: removeOnline,
      };
    }
    case 'NEW_DINEIN_ORDERS':
      return {
        ...state,
        newDinein: action.payload,
      };
    case 'NEW_TAKEAWAY_ORDERS':
      return {
        ...state,
        newTakeaway: action.payload,
      };
    case 'NEW_HOTEL_ORDERS':
      return {
        ...state,
        newHotel: action.payload,
      };
    case 'NEW_QSR_ORDERS':
      return {
        ...state,
        newQSR: action.payload,
      };
    case actionTypes.RESET_ORDERS:
      return {
        orderLoading: false,
        currentSelectedOrder: {_id: '', type: ''},
        dineinOrders: [],
        takeawayOrders: [],
        deliveryOrders: [],
        multiDropOrders: [],
        hotelOrders: [],
        qsrOrders: [],
        newDinein: false,
        newTakeaway: false,
        newHotel: false,
        newQSR: false,
        serviceList: [],
        deliveryNPOrders: [],
        deliveryMultiDropNPOrders: [],
        onlineOrders: [],
        storeLoyalty: [],
      };
    case actionTypes.LOG_OUT:
      return {
        orderLoading: false,
        currentSelectedOrder: {_id: '', type: ''},
        dineinOrders: [],
        takeawayOrders: [],
        deliveryOrders: [],
        multiDropOrders: [],
        hotelOrders: [],
        qsrOrders: [],
        deliveryNPOrders: [],
        deliveryMultiDropNPOrders: [],
        newDinein: false,
        newTakeaway: false,
        newHotel: false,
        newQSR: false,
        serviceList: [],
        onlineOrders: [],
        storeLoyalty: [],
      };
    default:
      return state;
  }
}

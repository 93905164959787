import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {setLoading} from '../miscActions';
import {printOrderBill} from '../orderActions';
import {toast} from 'react-toastify';

export const setSalesData = (data) => ({
  type: actionTypes.SET_SALES_DATA,
  payload: data,
});

export const removeOrderSummary = () => ({
  type: actionTypes.REMOVE_ORDER_SUMMARY,
});

export const getOrderSummary = (fromDate, toDate) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${restID}/orderSummary?from=${fromDate}&to=${toDate}`
      )
      .then((res) => {
        dispatch({type: actionTypes.SAVE_ORDER_SUMMARY, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getSalesInfo = (fromDate, toDate) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .get(
        `${API_URL}/stores/${restID}/orders?from=${fromDate}&to=${toDate}&limit=1000&page=1`
      )
      .then((res) => {
        dispatch(setSalesData(res.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const downloadCSV = (fromDate, toDate) => {
  return (dispatch, getState) => {
    const restID = getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stores/${restID}/sales?from=${fromDate}&to=${toDate}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'SalesDetails.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const salesBillPrint = (orderID, reason) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const data = {
      bill_print: true,
    };
    if (reason) {
      data['bill_print_reason'] = reason;
    }
    http
      .post(`${API_URL}/orders/${orderID}/print`, data)
      .then((res) => {
        dispatch({type: actionTypes.UPDATE_SALES_DATA, payload: res.data});
        if (res.data.bill_print) {
          dispatch(printOrderBill(res.data));
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setLoading(false));
      });
  };
};

import * as actionTypes from '../../actionTypes';

const initialState = {
  storeSongs: [],
  globalSongs: [],
  songsQueue: [],
  currentSong: {},
};

export default function jukeboxReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_JUKEBOX_STORE_SONGS:
      return {
        ...state,
        storeSongs: action.payload,
      };
    case actionTypes.INIT_JUKEBOX_GLOBAL_SONGS:
      return {
        ...state,
        globalSongs: action.payload,
      };
    case actionTypes.INIT_JUKEBOX_QUEUE_SONGS:
      return {
        ...state,
        songsQueue: action.payload,
      };
    case actionTypes.INIT_JUKEBOX_CURRENT_SONG:
      return {
        ...state,
        currentSong: action.payload,
      };
    case actionTypes.LOG_OUT:
      return {
        storeSongs: [],
        globalSongs: [],
        songsQueue: [],
        currentSong: {},
      };
    default:
      return state;
  }
}

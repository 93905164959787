import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function NotificationIcon() {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.1031 19.0994C21.0325 19.0536 20.5016 18.6816 19.9699 17.5564C18.9936 15.49 18.7886 12.5792 18.7886 10.5012C18.7886 10.4921 18.7884 10.4832 18.7881 10.4742C18.7775 7.72781 17.133 5.36034 14.7796 4.29811V2.67356C14.7796 1.19934 13.5823 0 12.1105 0H11.8893C10.4175 0 9.22013 1.19934 9.22013 2.67356V4.29802C6.85894 5.36367 5.21115 7.74323 5.21115 10.5012C5.21115 12.5792 5.00621 15.49 4.02985 17.5564C3.49824 18.6816 2.96729 19.0535 2.89669 19.0994C2.59941 19.2369 2.45283 19.5548 2.52123 19.8768C2.59027 20.2021 2.8943 20.4263 3.22632 20.4263H8.38008C8.40887 22.4017 10.0211 24 11.9999 24C13.9787 24 15.591 22.4017 15.6197 20.4263H20.7735C21.1055 20.4263 21.4096 20.2021 21.4786 19.8768C21.5469 19.5548 21.4004 19.2369 21.1031 19.0994ZM10.6264 2.67356C10.6264 1.97602 11.193 1.40855 11.8893 1.40855H12.1105C12.8069 1.40855 13.3734 1.97602 13.3734 2.67356V3.84155C12.9296 3.74981 12.4702 3.70153 11.9997 3.70153C11.5294 3.70153 11.0701 3.74977 10.6265 3.84141V2.67356H10.6264ZM11.9999 22.5915C10.7965 22.5915 9.81488 21.625 9.78633 20.4264H14.2134C14.1849 21.625 13.2033 22.5915 11.9999 22.5915ZM14.8312 19.0177C14.831 19.0177 4.82874 19.0177 4.82874 19.0177C4.95038 18.8282 5.07418 18.6135 5.19723 18.371C6.1396 16.5127 6.61744 13.8649 6.61744 10.5012C6.61744 7.5285 9.03193 5.11008 11.9997 5.11008C14.9674 5.11008 17.3819 7.5285 17.3819 10.5035C17.3819 10.5122 17.3821 10.5208 17.3824 10.5294C17.3851 13.8797 17.8629 16.518 18.8026 18.371C18.9256 18.6136 19.0495 18.8282 19.1711 19.0177H14.8312Z" />
    </SvgIcon>
  );
}

export default NotificationIcon;

import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import MenuIcon from './svgs/MenuIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuDrawer from './components/MenuDrawer';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import HotMenu from './components/HotMenu';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OrderHeading from '../../pages/DashboardPage/modules/OrderHeading';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [, i18n] = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const restName = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.business_name
      ? state.auth.restaurant.business_name
      : state.auth.restaurant.name
      ? state.auth.restaurant.name.en
      : ''
  );

  const currentSelectedTable = useSelector(
    (state) => state.dine.currentSelectedTable
  );
  const currentSelectedHold = useSelector(
    (state) => state.hold.currentSelectedHold
  );
  const currentSelectedOrder = useSelector(
    (state) => state.orders.currentSelectedOrder
  );

  const language = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    if (
      language.includes(window.localStorage.i18nextLng.split('-')[0]) ||
      window.localStorage.i18nextLng.split('-')[0] === 'en'
    ) {
      i18n.changeLanguage(window.localStorage.i18nextLng.split('-')[0]);
    } else {
      i18n.changeLanguage('en');
    }
    // eslint-disable-next-line
  }, []);

  const selectedOrder = useSelector((state) => {
    switch (currentSelectedOrder && currentSelectedOrder.type) {
      case 'dinein': {
        const index = state.orders.dineinOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.dineinOrders[index];
        } else {
          return undefined;
        }
      }
      case 'takeaway': {
        const index = state.orders.takeawayOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.takeawayOrders[index];
        } else {
          return undefined;
        }
      }
      case 'delivery': {
        const index = state.orders.deliveryOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.deliveryOrders[index];
        } else {
          return undefined;
        }
      }
      case 'group-delivery': {
        const index = state.orders.multiDropOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.multiDropOrders[index];
        } else {
          return undefined;
        }
      }
      case 'hotel': {
        const index = state.orders.hotelOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.hotelOrders[index];
        } else {
          return undefined;
        }
      }
      case 'qsr': {
        const index = state.orders.qsrOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.qsrOrders[index];
        } else {
          return undefined;
        }
      }
      case 'online': {
        const index = state.orders.onlineOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.onlineOrders[index];
        } else {
          return undefined;
        }
      }
      default:
        return undefined;
    }
  });

  const selectedHold = useSelector((state) => {
    let temp = undefined;
    if (
      state.hold.holdQueue.length > 0 &&
      state.hold.currentSelectedHold &&
      state.hold.currentSelectedHold._id
    ) {
      const index = state.hold.holdQueue.findIndex(
        (a) => a._id === state.hold.currentSelectedHold._id
      );
      if (index > -1) {
        temp = {...state.hold.holdQueue[index]};
      }
    } else if (
      state.dine.dineinQueue.length > 0 &&
      state.dine.currentSelectedTable &&
      state.dine.currentSelectedTable._id
    ) {
      const index = state.dine.dineinQueue.findIndex(
        (a) => a.table_id === state.dine.currentSelectedTable._id
      );
      if (index > -1) {
        temp = {...state.dine.dineinQueue[index]};
      }
    }
    return temp;
  });

  return (
    <div className={classes.root}>
      {!matches &&
      ((currentSelectedTable && currentSelectedTable._id) ||
        (currentSelectedOrder && currentSelectedOrder.type) ||
        (currentSelectedHold && currentSelectedHold._id)) ? (
        <>
          <div style={{marginLeft: 12, marginRight: 12, width: '100%'}}>
            {selectedHold ? (
              <OrderHeading
                orderForDinein={selectedHold && selectedHold.table_no}
                orderFor={
                  selectedHold && selectedHold.table_no
                    ? selectedHold.table_no
                    : selectedHold && selectedHold.type
                }
                enableCloseOrder
              />
            ) : (
              selectedOrder && (
                <OrderHeading selectedOrder={selectedOrder} enableCloseOrder />
              )
            )}
          </div>
        </>
      ) : (
        <>
          <div className={classes.leftDiv}>
            <div className={classes.flexBox}>
              <IconButton onClick={() => setMenuOpen(true)}>
                <MenuIcon />
              </IconButton>
              <div
                className={classes.titleText}
                onClick={() => {
                  if (dashboardAccess && dashboardAccess.includes('cashier')) {
                    if (
                      window.location.pathname &&
                      !window.location.pathname.includes('dashboard')
                    ) {
                      dispatch(push('/home'));
                    }
                  } else if (
                    dashboardAccess &&
                    dashboardAccess.includes('qms')
                  ) {
                    if (
                      window.location.pathname &&
                      !window.location.pathname.includes('qms')
                    ) {
                      dispatch(push('/qms'));
                    }
                  }
                }}
              >
                {restName}
              </div>
            </div>
          </div>
          <div className={classes.rightDiv}>
            <HotMenu />
          </div>
        </>
      )}
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default Header;

import * as actionTypes from '../../actionTypes';

const initialState = {
  expenses: [],
  directPayments: [],
};

export default function expensesReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_EXPENSE:
      return {
        ...state,
        expenses: action.payload,
      };
    case actionTypes.ADD_TO_EXPENSE:
      const addExpense = [...state.expenses];
      addExpense.unshift(action.payload);
      return {
        ...state,
        expenses: addExpense,
      };
    case actionTypes.DELETE_EXPENSE:
      const deleteExpense = [...state.expenses];
      const deleteIndex = deleteExpense.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteIndex > -1) {
        deleteExpense.splice(deleteIndex, 1);
      }
      return {
        ...state,
        expenses: deleteExpense,
      };
    case actionTypes.INIT_DIRECT_PAYMENT:
      return {
        ...state,
        directPayments: action.payload,
      };
    case 'LOGOUT':
      return {
        expenses: [],
        directPayments: [],
      };
    default:
      return state;
  }
}

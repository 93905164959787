import * as actionTypes from '../../actionTypes';
import {getRestData} from '../authActions';
import {getItemInfo, getCategoryInfo} from '../itemActions';
import {http} from '../../http';
import {API_URL, POS_PRINT_URL} from '../../../utils/constants';
import axios from 'axios';
import {getTableInfo} from '../dineActions';
import {getPreferences} from '../prefActions';
import {
  getMultiDropOrders,
  getOrders,
  getServices,
  getStoreLoyalty,
} from '../orderActions';

export const setLoading = (status) => ({
  type: actionTypes.SET_LOADING,
  payload: status,
});

export const setType = (type) => ({
  type: actionTypes.SELECTED_TYPE,
  payload: type,
});

export const setTypeIndex = (index) => ({
  type: actionTypes.TYPE_INDEX,
  payload: index,
});

export const setShowDishes = (status) => ({
  type: actionTypes.SET_SHOW_DISHES,
  payload: status,
});

export const addPrintJob = (id) => ({
  type: actionTypes.SET_PRINT_JOB,
  payload: id,
});

export const removePrintJob = (id) => ({
  type: actionTypes.REMOVE_PRINT_JOB,
  payload: id,
});

export const fetchSource = (id, access) => {
  return async (dispatch) => {
    await dispatch(getRestData(id));
    await dispatch(getTableInfo(id));
    if (access && access.includes('cashier')) {
      await dispatch(getItemInfo(id));
      await dispatch(getCategoryInfo());
      await dispatch(getPreferences());
      await dispatch(getOrders());
      await dispatch(getServices());
      await dispatch(getMultiDropOrders());
      await dispatch(getStoreLoyalty());
    }
  };
};

export const ablyAck = (id) => {
  return () => {
    axios
      .put(`${API_URL}/ably/${id}`, {ack: true})
      .then(() => console.log('acknowledged'))
      .catch(() => console.log('unable to ack'));
  };
};

export const printKOT = (
  tableName,
  kotData,
  kotNumber,
  cancelled,
  orderID,
  kotID,
  timestamp,
  cancelledPrint
) => {
  return (dispatch, getState) => {
    const language =
      getState().auth.restaurant && getState().auth.restaurant.opted_languages;
    const lang =
      language &&
      language.includes(window.localStorage.i18nextLng.split('-')[0])
        ? window.localStorage.i18nextLng.split('-')[0]
        : 'en';
    // console.log('printHobb', getState().misc);
    const printJob = getState().misc.printJob;
    const kot = {
      table_no: tableName,
      kot_no: kotNumber,
      kot_data: kotData,
      cancelled: cancelled,
      timestamp: timestamp ? timestamp : new Date().toISOString(),
    };
    let url = `${POS_PRINT_URL}/kot?lang=${lang}`;
    if (cancelledPrint) {
      url += `&cancelled_print=${cancelledPrint}`;
    }
    if (!printJob.includes(kotID)) {
      dispatch(addPrintJob(kotID));
      axios
        .post(url, kot)
        .then(() => {
          if (kotID && orderID) {
            http
              .post(`${API_URL}/orders/${orderID}/kot/${kotID}`, {
                printed: true,
                canceled_print: false,
              })
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch(() => {
          // To make kot print to retry if fails
          // http
          //   .post(`${API_URL}/orders/${orderID}/kot/${kotID}`, {
          //     printed: true,
          //     canceled_print: false,
          //   })
          //   .then(() => {})
          //   .catch(() => {});
        });
    }
    setTimeout(() => {
      dispatch(removePrintJob(kotID));
    }, 2000);
  };
};

export const printBill = (billData, orderID, printTimes) => {
  return (dispatch, getState) => {
    const billPrintCount =
      getState().pref.preferences && getState().pref.preferences.print_count
        ? getState().pref.preferences.print_count
        : 1;
    let printCount = billPrintCount;
    if (printTimes && printTimes > 1) {
      printCount += printTimes;
    }
    const language =
      getState().auth.restaurant && getState().auth.restaurant.opted_languages;
    const lang =
      language &&
      language.includes(window.localStorage.i18nextLng.split('-')[0])
        ? window.localStorage.i18nextLng.split('-')[0]
        : 'en';
    const printJob = getState().misc.printJob;
    if (!printJob.includes(orderID)) {
      dispatch(addPrintJob(orderID));
      if (printCount) {
        for (let i = 0; i < printCount; i++) {
          axios
            .post(`${POS_PRINT_URL}/bill?lang=${lang}`, billData)
            .then(() => {
              if (orderID) {
                const id = orderID.includes('-')
                  ? orderID.split('-').splice(0, 1)
                  : orderID;
                http
                  .post(`${API_URL}/orders/${id}/print`, {
                    bill_print: false,
                  })
                  .then(() => {})
                  .catch(() => {});
              }
            })
            .catch(() => {});
        }
      } else {
        axios
          .post(`${POS_PRINT_URL}/bill?lang=${lang}`, billData)
          .then(() => {
            if (orderID) {
              const id = orderID.includes('-')
                ? orderID.split('-').splice(0, 1)
                : orderID;
              http
                .post(`${API_URL}/orders/${id}/print`, {
                  bill_print: false,
                })
                .then(() => {})
                .catch(() => {});
            }
          })
          .catch(() => {});
      }
    }
    setTimeout(() => {
      dispatch(removePrintJob(orderID));
    }, 2000);
  };
};

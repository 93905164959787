import * as actionTypes from '../../actionTypes';

const initialState = {
  list: [],
  totalOrder: 0,
  totalBill: 0,
  orderSummary: {},
};

export default function salesReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SALES_DATA:
      const data =
        action.payload && action.payload.orders ? action.payload.orders : [];
      const orderCount =
        action.payload && action.payload.orderCount
          ? action.payload.orderCount
          : 0;
      const orderTotal =
        action.payload &&
        action.payload.orderTotal &&
        action.payload.orderTotal[0]
          ? action.payload.orderTotal[0].total
          : 0;
      return {
        ...state,
        list: data,
        totalOrder: orderCount,
        totalBill: orderTotal,
      };
    case actionTypes.UPDATE_SALES_DATA:
      const updateList = [...state.list];
      const updateIndex = updateList.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateIndex > -1) {
        updateList[updateIndex] = action.payload;
      }
      return {
        ...state,
        list: updateList,
      };
    case actionTypes.SAVE_ORDER_SUMMARY:
      return {
        ...state,
        orderSummary: action.payload,
      };
    case actionTypes.REMOVE_ORDER_SUMMARY:
      return {
        ...state,
        orderSummary: {},
      };
    case actionTypes.RESET_SALES:
      return {
        list: [],
        totalOrder: 0,
        totalBill: 0,
        orderSummary: {},
      };
    case actionTypes.LOG_OUT:
      return {
        list: [],
        totalOrder: 0,
        totalBill: 0,
        orderSummary: {},
      };
    default:
      return state;
  }
}

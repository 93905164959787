import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  notificationUIRoot: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  notificationTitle: {
    fontSize: 20,
    fontWeight: 500,
    padding: theme.spacing(4),
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    padding: `${theme.spacing(2)}px !important`,
  },
  queueStyle: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/* eslint-disable camelcase */
import _ from 'lodash';
import * as actionTypes from '../../actionTypes';

const initState = {
  tableData: [],
  hotelData: [],
  currentSelectedTable: {},
  dineinQueue: [],
  waiters: [],
  bookedTable: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_TABLE:
      const init_table = [];
      const init_hotel = [];
      action.payload.forEach((obj) => {
        if (obj.__type === 'dinein') {
          init_table.push(obj);
        } else if (obj.__type === 'hotel') {
          init_hotel.push(obj);
        }
      });
      return {
        ...state,
        tableData: init_table,
        hotelData: init_hotel,
      };
    case actionTypes.INIT_WAITER:
      return {
        ...state,
        waiters: action.payload,
      };
    case actionTypes.UPDATE_TABLE_CURRENT_ORDER:
      const updateTableData = [...state.tableData];
      const updateTableData_index = state.tableData.findIndex(
        (val) => val._id === action.payload.table_id
      );
      if (updateTableData_index > -1) {
        updateTableData[updateTableData_index]['current_order'] =
          action.payload.current_order;
      }
      return {
        ...state,
        tableData: updateTableData,
      };
    case actionTypes.CURRENT_SELECTED_TABLE:
      return {
        ...state,
        currentSelectedTable: action.payload,
      };
    case actionTypes.CREATE_DINEIN_QUEUE: {
      const tempDineinQueue = [...state.dineinQueue];
      tempDineinQueue.push(action.payload);
      return {
        ...state,
        dineinQueue: tempDineinQueue,
      };
    }
    case actionTypes.ADD_ITEM_TO_DINEIN_QUEUE:
      const add_item_index = state.dineinQueue.findIndex(
        (val) => val.table_id === action.payload.table_id
      );

      const getAddonPrice = (item) => {
        if (item.addons.length === 0) {
          return item.base_price;
        } else {
          let temp = item.base_price;
          item.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };

      const indexItem =
        state.dineinQueue[add_item_index] &&
        state.dineinQueue[add_item_index].items.findIndex(
          (value) =>
            value.item_id === action.payload.item._id &&
            _.isEqual(value.addons, action.payload.item.addons)
        );

      if (indexItem !== -1 && state.dineinQueue[add_item_index]) {
        const item = {
          ...state.dineinQueue[add_item_index].items[indexItem],
          qty: state.dineinQueue[add_item_index].items[indexItem].qty + 1,
          total_price:
            state.dineinQueue[add_item_index].items[indexItem].item_price *
            (state.dineinQueue[add_item_index].items[indexItem].qty + 1),
        };
        return {
          ...state,
          dineinQueue: [
            ...state.dineinQueue.slice(0, add_item_index),
            {
              ...state.dineinQueue[add_item_index],
              items: [
                ...state.dineinQueue[add_item_index].items.slice(0, indexItem),
                item,
                ...state.dineinQueue[add_item_index].items.slice(indexItem + 1),
              ],
            },
            ...state.dineinQueue.slice(add_item_index + 1),
          ],
        };
      } else {
        const item = {
          item_id: action.payload.item._id,
          name: action.payload.item.name,
          item_price: getAddonPrice(action.payload.item),
          total_price: getAddonPrice(action.payload.item),
          addons: action.payload.item.addons,
          qty: 1,
          note: '',
        };
        return {
          ...state,
          dineinQueue: [
            ...state.dineinQueue.slice(0, add_item_index),
            {
              ...state.dineinQueue[add_item_index],
              items: [...state.dineinQueue[add_item_index].items, item],
            },
            ...state.dineinQueue.slice(add_item_index + 1),
          ],
        };
      }
    case actionTypes.INCREMENT_ITEM_DINEIN_QUEUE:
      const increment_index = state.dineinQueue.findIndex(
        (val) => val.table_id === action.payload.table_id
      );
      const increment_item_index = state.dineinQueue[
        increment_index
      ].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );

      const increment_item = {
        ...state.dineinQueue[increment_index].items[increment_item_index],
        qty:
          state.dineinQueue[increment_index].items[increment_item_index].qty +
          1,
        total_price:
          state.dineinQueue[increment_index].items[increment_item_index]
            .item_price *
          (state.dineinQueue[increment_index].items[increment_item_index].qty +
            1),
      };

      return {
        ...state,
        dineinQueue: [
          ...state.dineinQueue.slice(0, increment_index),
          {
            ...state.dineinQueue[increment_index],
            items: [
              ...state.dineinQueue[increment_index].items.slice(
                0,
                increment_item_index
              ),
              increment_item,
              ...state.dineinQueue[increment_index].items.slice(
                increment_item_index + 1
              ),
            ],
          },
          ...state.dineinQueue.slice(increment_index + 1),
        ],
      };
    case actionTypes.COMPLIMENT_ITEM_DINEIN_QUEUE:
      const compliment_index = state.dineinQueue.findIndex(
        (val) => val.table_id === action.payload.table_id
      );
      const compliment_item_index = state.dineinQueue[
        compliment_index
      ].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );
      const compliment_item = {
        ...state.dineinQueue[compliment_index].items[compliment_item_index],
        is_complimentary:
          state.dineinQueue[compliment_index].items[compliment_item_index] &&
          state.dineinQueue[compliment_index].items[compliment_item_index]
            .is_complimentary
            ? false
            : true,
        complimentary_reason: action.payload.reason
          ? action.payload.reason
          : '',
      };
      return {
        ...state,
        dineinQueue: [
          ...state.dineinQueue.slice(0, compliment_index),
          {
            ...state.dineinQueue[compliment_index],
            items: [
              ...state.dineinQueue[compliment_index].items.slice(
                0,
                compliment_item_index
              ),
              compliment_item,
              ...state.dineinQueue[compliment_index].items.slice(
                compliment_item_index + 1
              ),
            ],
          },
          ...state.dineinQueue.slice(compliment_index + 1),
        ],
      };
    case actionTypes.UPDATE_NOTE_DINEIN_QUEUE:
      const note_index = state.dineinQueue.findIndex(
        (val) => val.table_id === action.payload.table_id
      );
      const note_item_index = state.dineinQueue[note_index].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );
      const note_item = {
        ...state.dineinQueue[note_index].items[note_item_index],
        note: action.payload.value,
      };
      return {
        ...state,
        dineinQueue: [
          ...state.dineinQueue.slice(0, note_index),
          {
            ...state.dineinQueue[note_index],
            items: [
              ...state.dineinQueue[note_index].items.slice(0, note_item_index),
              note_item,
              ...state.dineinQueue[note_index].items.slice(note_item_index + 1),
            ],
          },
          ...state.dineinQueue.slice(note_index + 1),
        ],
      };
    case actionTypes.DECREMENT_ITEM_DINEIN_QUEUE:
      const decrement_index = state.dineinQueue.findIndex(
        (val) => val.table_id === action.payload.table_id
      );
      const decrement_item_index = state.dineinQueue[
        decrement_index
      ].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );

      const currQty =
        state.dineinQueue[decrement_index].items[decrement_item_index].qty;

      const decrement_item = {
        ...state.dineinQueue[decrement_index].items[decrement_item_index],
        qty: currQty === 1 ? 1 : currQty - 1,
        total_price:
          state.dineinQueue[decrement_index].items[decrement_item_index]
            .item_price * (currQty === 1 ? 1 : currQty - 1),
      };

      return {
        ...state,
        dineinQueue: [
          ...state.dineinQueue.slice(0, decrement_index),
          {
            ...state.dineinQueue[decrement_index],
            items: [
              ...state.dineinQueue[decrement_index].items.slice(
                0,
                decrement_item_index
              ),
              decrement_item,
              ...state.dineinQueue[decrement_index].items.slice(
                decrement_item_index + 1
              ),
            ],
          },
          ...state.dineinQueue.slice(decrement_index + 1),
        ],
      };
    case actionTypes.REMOVE_ITEM_FROM_DINEIN_QUEUE:
      const remove_item_index = state.dineinQueue.findIndex(
        (val) => val.table_id === action.payload.table_id
      );

      const deleteItems = state.dineinQueue[remove_item_index].items.filter(
        (val) =>
          val.item_id !== action.payload.item.item_id ||
          !_.isEqual(val.addons, action.payload.item.addons)
      );

      return {
        ...state,
        dineinQueue: [
          ...state.dineinQueue.slice(0, remove_item_index),
          {
            ...state.dineinQueue[remove_item_index],
            items: deleteItems,
          },
          ...state.dineinQueue.slice(remove_item_index + 1),
        ],
      };
    case actionTypes.REMOVE_DINEIN_QUEUE: {
      const removeDineinQueue = [...state.dineinQueue];
      const removeDineinQueue_index = removeDineinQueue.findIndex(
        (val) => val.table_id === action.payload
      );
      if (removeDineinQueue_index > -1) {
        removeDineinQueue.splice([removeDineinQueue_index], 1);
      }
      return {
        ...state,
        dineinQueue: removeDineinQueue,
        currentSelectedTable: {},
      };
    }
    case actionTypes.BOOKED_TABLE:
      return {
        ...state,
        bookedTable: action.payload,
      };
    case actionTypes.REMOVE_BOOKED_TABLE:
      const removeBooked = [...state.bookedTable];
      const removeBookedIndex = removeBooked.findIndex(
        (a) => a._id === action.payload
      );
      if (removeBookedIndex > -1) {
        removeBooked.splice(removeBookedIndex, 1);
      }
      return {
        ...state,
        bookedTable: removeBooked,
      };
    case actionTypes.RESET_TABLE:
      return {
        tableData: [],
        hotelData: [],
        currentSelectedTable: {},
        dineinQueue: [],
        bookedTable: [],
      };
    case actionTypes.LOG_OUT:
      return {
        tableData: [],
        hotelData: [],
        currentSelectedTable: {},
        dineinQueue: [],
        bookedTable: [],
      };
    default:
      return state;
  }
};

export default reducer;

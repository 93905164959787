import React from 'react';

function ExpensesIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#00acea"
        d="M477.03,391.95v49.48a45.345,45.345,0,0,1-45.35,45.35H80.32a45.345,45.345,0,0,1-45.35-45.35V182.52a45.351,45.351,0,0,1,45.35-45.35H96.65a105.864,105.864,0,0,0,211.38-.01l.18.01H431.68a45.351,45.351,0,0,1,45.35,45.35v49.49H371.3a45.345,45.345,0,0,0-45.35,45.35V346.6a45.351,45.351,0,0,0,45.35,45.35Z"
      />
      <path
        fill="#00efd1"
        d="M477.03,232.01V391.95H371.3a45.351,45.351,0,0,1-45.35-45.35V277.36a45.345,45.345,0,0,1,45.35-45.35Z"
      />
      <path
        fill="#f4b844"
        d="M308.21,131.09q0,3.06-.18,6.07a105.864,105.864,0,0,1-211.38.01q-.18-3.015-.18-6.08a105.87,105.87,0,1,1,211.74,0Zm-29.16,0a76.71,76.71,0,1,0-76.71,76.71A76.7,76.7,0,0,0,279.05,131.09Z"
      />
      <circle cx="202.34" cy="131.09" r="76.71" fill="#fedb41" />
      <path
        fill="#083863"
        d="M431.68,129H316.186a113.862,113.862,0,0,0-227.692,0H80.32A53.53,53.53,0,0,0,27,182.52V441.43C27,470.847,50.9,495,80.32,495H431.68C461.1,495,485,470.847,485,441.43V182.52A53.53,53.53,0,0,0,431.68,129ZM202.34,33.22a97.981,97.981,0,0,1,97.87,97.87c0,1.874-.056,3.756-.167,5.619a97.864,97.864,0,0,1-195.4.01l0-.026c-.111-1.848-.166-3.733-.166-5.6A97.981,97.981,0,0,1,202.34,33.22ZM431.68,479H80.32A37.556,37.556,0,0,1,43,441.43V182.52A37.513,37.513,0,0,1,80.32,145h9.027a113.867,113.867,0,0,0,225.987,0H431.68A37.513,37.513,0,0,1,469,182.52V224H371.3A53.376,53.376,0,0,0,318,277.36V346.6A53.41,53.41,0,0,0,371.3,400H469v41.43A37.556,37.556,0,0,1,431.68,479ZM469,240V384H371.3A37.392,37.392,0,0,1,334,346.6V277.36A37.358,37.358,0,0,1,371.3,240Z"
      />
      <path
        fill="#083863"
        d="M389,343.26a8,8,0,0,0,8-8V288.7a8,8,0,0,0-16,0v46.56A8,8,0,0,0,389,343.26Z"
      />
      <path
        fill="#083863"
        d="M202.438,163.037c-.146-.008-.291-.022-.438-.022-.02,0-.039,0-.059,0a12.258,12.258,0,0,1-11.811-12.2,8,8,0,0,0-16,0A28.371,28.371,0,0,0,194,177.754V182a8,8,0,0,0,16,0v-4.025a28.211,28.211,0,0,0-7.66-55.365,12.215,12.215,0,1,1,12.22-12.21,8,8,0,1,0,16,0A28.266,28.266,0,0,0,209,82.981V77a8,8,0,0,0-16,0v6.777a28.212,28.212,0,0,0,9.34,54.833,12.214,12.214,0,0,1,.1,24.427Z"
      />
      <path
        fill="#083863"
        d="M202.34,215.8a84.71,84.71,0,1,0-84.71-84.71A84.806,84.806,0,0,0,202.34,215.8Zm0-153.42a68.71,68.71,0,1,1-68.71,68.71A68.788,68.788,0,0,1,202.34,62.38Z"
      />
      <path
        fill="#083863"
        d="M198,251a8,8,0,0,0-8,8v60a8,8,0,0,0,16,0V259A8,8,0,0,0,198,251Z"
      />
      <path
        fill="#083863"
        d="M233,251a8,8,0,0,0-8,8v34a8,8,0,0,0,16,0V259A8,8,0,0,0,233,251Z"
      />
      <path
        fill="#083863"
        d="M163,251a8,8,0,0,0-8,8v34a8,8,0,0,0,16,0V259A8,8,0,0,0,163,251Z"
      />
    </svg>
  );
}

export default ExpensesIcon;

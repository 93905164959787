import * as actionTypes from '../../actionTypes';

const initialState = {
  preferences: {},
};

export default function prefReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_PREFERENCES:
      return {
        ...state,
        preferences: {...action.payload},
      };
    case actionTypes.RESET_PREF:
      return {
        preferences: {},
      };
    case actionTypes.LOG_OUT:
      return {
        preferences: {},
      };
    default:
      return state;
  }
}

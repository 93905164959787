import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paymentRadioGroup: {
    flexDirection: 'row !important',
  },
  searchInput: {
    padding: '16px 16px 0',
  },
  // PredictionCard
  predictionRoot: {
    padding: '16px 16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'white',
    cursor: 'pointer',
  },
  predictionIcon: {
    paddingRight: 16,
  },
  predictionLine1: {
    marginBottom: 6,
  },
  predictionLine2: {
    fontSize: 10,
  },
  useCurrentLocation: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    paddingTop: 16,
    paddingBottom: 16,
  },
  useCurrentLocationText: {
    marginLeft: 12,
  },
  useCurrentLocationErrorText: {
    fontSize: 10,
    marginTop: 4,
  },
  // OrderHeading
  takeawayOrderNumber: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));

import React from 'react';
import {useStyles} from './styles';
import Button from '@material-ui/core/Button';

function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.text}>Page Not Found</div>
      <Button
        color={'primary'}
        variant={'outlined'}
        size={'small'}
        onClick={() => window.location.replace('/home')}
      >
        Go to Home
      </Button>
    </div>
  );
}

export default NotFound;

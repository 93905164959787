import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {removeOrderSummary} from '../../services/actions/salesActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function OrderSummary() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState('');
  const orderSummary = useSelector((state) => state.sales.orderSummary);
  const currency = useSelector(
    (state) =>
      state.auth.restaurant &&
      state.auth.restaurant.default_currency &&
      state.auth.restaurant.default_currency.symbol
  );

  const handleChange = (panel) => {
    if (panel === expand) {
      setExpand('');
    } else {
      setExpand(panel);
    }
  };
  return (
    <Dialog
      open={orderSummary && orderSummary.success}
      maxWidth={'lg'}
      fullWidth
    >
      <DialogTitle>{t('Order Summary')}</DialogTitle>
      <DialogContent>
        <Accordion
          expanded={expand === 'success'}
          onChange={() => handleChange('success')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="success-order-summary"
          >
            <div
              style={{
                flexBasis: '33.33%',
                flexShrink: 0,
                fontWeight: 'bold',
                marginLeft: 12,
              }}
            >
              Success Orders
            </div>
            <div style={{opacity: 0.75, fontSize: 12}}>
              {'No.of.Orders(#) : '}
              {orderSummary.success && orderSummary.success.count}
            </div>
            <div style={{flex: 1, textAlign: 'end'}}>
              {' '}
              {currency}{' '}
              {orderSummary.success && orderSummary.success.total
                ? orderSummary.success.total
                : 0}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
            >
              {orderSummary.success &&
                orderSummary.success.data &&
                orderSummary.success.data.length > 0 &&
                orderSummary.success.data.map((obj, index) => (
                  <div style={{width: 150, padding: 24}} key={index}>
                    <div>
                      {obj.name} - # {obj.count}
                    </div>
                    <div style={{marginTop: 16}}>
                      {currency} {obj.total}
                    </div>
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expand === 'cancel'}
          onChange={() => handleChange('cancel')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="cancelled-order-summary"
          >
            <div
              style={{
                flexBasis: '33.33%',
                flexShrink: 0,
                fontWeight: 'bold',
                marginLeft: 12,
              }}
            >
              Cancelled Orders
            </div>
            <div style={{opacity: 0.75, fontSize: 12}}>
              {'No.of.Orders(#) : '}
              {orderSummary.cancel && orderSummary.cancel.count}
            </div>
            <div style={{flex: 1, textAlign: 'end'}}>
              {' '}
              {currency}{' '}
              {orderSummary.cancel && orderSummary.cancel.total
                ? orderSummary.cancel.total
                : 0}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
            >
              {orderSummary.cancel &&
                orderSummary.cancel.data &&
                orderSummary.cancel.data.length > 0 &&
                orderSummary.cancel.data.map((obj, index) => (
                  <div style={{width: 150, padding: 24}} key={index}>
                    <div>
                      {obj.name} - # {obj.count}
                    </div>
                    <div style={{marginTop: 16}}>
                      {currency} {obj.total}
                    </div>
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expand === 'complementary'}
          onChange={() => handleChange('complementary')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="complementary-order-summary"
          >
            <div
              style={{
                flexBasis: '33.33%',
                flexShrink: 0,
                fontWeight: 'bold',
                marginLeft: 12,
              }}
            >
              Complementary
            </div>
            <div style={{opacity: 0.75, fontSize: 12}}>
              {'No.of.Orders(#) : '}
              {orderSummary.complementary && orderSummary.complementary.count}
            </div>
            <div style={{flex: 1, textAlign: 'end'}}>
              {' '}
              {currency}{' '}
              {orderSummary.complementary && orderSummary.complementary.total
                ? orderSummary.complementary.total
                : 0}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
            >
              {orderSummary.complementary &&
                orderSummary.complementary.data &&
                orderSummary.complementary.data.length > 0 &&
                orderSummary.complementary.data.map((obj, index) => (
                  <div style={{width: 150, padding: 24}} key={index}>
                    <div>
                      {obj.name} - # {obj.count}
                    </div>
                    <div style={{marginTop: 16}}>
                      {currency} {obj.total}
                    </div>
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expand === 'payment'}
          onChange={() => handleChange('payment')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="payment-order-summary"
          >
            <div
              style={{
                flexBasis: '33.33%',
                flexShrink: 0,
                fontWeight: 'bold',
                marginLeft: 12,
              }}
            >
              Payment Methods
            </div>
            <div style={{flex: 1, textAlign: 'end'}}>
              {' '}
              {currency}{' '}
              {orderSummary.success && orderSummary.success.total
                ? orderSummary.success.total
                : 0}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
            >
              <div style={{width: 150, padding: 24}}>
                {'Cash'} - {currency}{' '}
                {orderSummary.paymentMethods && orderSummary.paymentMethods.cash
                  ? orderSummary.paymentMethods.cash
                  : 0}
              </div>
              <div style={{width: 150, padding: 24}}>
                {'Card'} - {currency}{' '}
                {orderSummary.paymentMethods && orderSummary.paymentMethods.card
                  ? orderSummary.paymentMethods.card
                  : 0}
              </div>
              <div style={{width: 150, padding: 24}}>
                {'Online'} - {currency}{' '}
                {orderSummary.paymentMethods &&
                orderSummary.paymentMethods.online
                  ? orderSummary.paymentMethods.online
                  : 0}
              </div>
              {orderSummary.paymentMethods &&
                orderSummary.paymentMethods.wallet &&
                orderSummary.paymentMethods.wallet.length > 0 &&
                orderSummary.paymentMethods.wallet.map((wallet, index) => (
                  <div key={index} style={{width: 150, padding: 24}}>
                    {wallet.wallet} - {currency}{' '}
                    {wallet.amount ? wallet.amount : 0}
                  </div>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size={'small'}
          onClick={() => {
            dispatch(removeOrderSummary());
          }}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderSummary;

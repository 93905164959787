import * as actionTypes from '../../actionTypes';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import {toast} from 'react-toastify';

export const getStoreSongs = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const storeID = getState().auth.restData.store_id;
    http
      .get(`${API_URL}/jukebox/songs/${storeID}`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_JUKEBOX_STORE_SONGS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get store songs');
      });
  };
};

export const addStoreSong = (receivedFile, request) => {
  return (dispatch) => {
    const data = new FormData();
    data.append('files', receivedFile);
    data.append('name', request.name);
    data.append('artist', request.artist);
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/jukebox/audio/store`, data)
      .then(() => {
        toast.success('Uploaded successfully, refreshing in 5 sec');
        setTimeout(() => {
          dispatch(getStoreSongs());
        }, 5000);
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Upload error');
        dispatch(setLoading(false));
      });
  };
};

export const getGlobalSongs = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/jukebox/songs`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_JUKEBOX_GLOBAL_SONGS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get global songs');
      });
  };
};

export const getSongsQueue = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/jukebox/store/${storeID}/queue`)
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch({
          type: actionTypes.INIT_JUKEBOX_QUEUE_SONGS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to get songs queue');
      });
  };
};

export const addSongToQueue = (songID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/jukebox/add/q`, {
        musicIds: [songID],
      })
      .then(async (res) => {
        await dispatch(setLoading(false));
        await dispatch(getSongsQueue());
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to add');
      });
  };
};

export const removeSongFromQueue = (songID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/jukebox/store/${songID}`)
      .then(async () => {
        await dispatch(setLoading(false));
        await dispatch(getSongsQueue());
      })
      .catch(() => {
        dispatch(setLoading(false));
        toast.error('unable to remove');
      });
  };
};

export const getCurrentSong = (noLoading) => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData.store_id;
    if (!noLoading) {
      dispatch(setLoading(true));
    }
    http
      .get(`${API_URL}/jukebox/store/${storeID}/current`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.INIT_JUKEBOX_CURRENT_SONG,
          payload: res.data,
        });
        if (!noLoading) {
          await dispatch(setLoading(false));
        }
      })
      .catch(() => {
        if (!noLoading) {
          dispatch(setLoading(false));
        }
      });
  };
};

export const stopPlayingSong = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/jukebox/store/${storeID}/pause`)
      .then(async () => {
        await dispatch(getCurrentSong());
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const startPlayingSong = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/jukebox/store/${storeID}/play`)
      .then(async () => {
        await dispatch(getCurrentSong());
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const playNextSong = () => {
  return (dispatch, getState) => {
    const storeID = getState().auth.restData.store_id;
    http
      .post(`${API_URL}/jukebox/songs/next/${storeID}`)
      .then(async () => {
        await dispatch(getCurrentSong(true));
      })
      .catch(() => {});
  };
};

import {http} from '../../http';
import {setLoading} from '../miscActions';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {toast} from 'react-toastify';

export const getActivetables = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/orders/${id}/running_tables`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.INIT_ACTIVE_TABLES,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getUserInQueue = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/waiting_queue/${id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.INIT_USER_QUEUE,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const markUserInQueue = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/waiting_queue/${id}`)
      .then(async () => {
        await dispatch({
          type: actionTypes.REMOVE_USER_QUEUE,
          payload: id,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const notifyUserInQueue = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/waiting_queue/${id}/notify`)
      .then(async () => {
        toast.success('Customer notified through sms');
        await dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('Unable to notify customer');
        dispatch(setLoading(false));
      });
  };
};

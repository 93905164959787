import React, {useEffect, useState} from 'react';
import {useStyles} from '../styles';
import RefreshIcon from '../svgs/RefreshIcon';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchSource,
  getActivetables,
  updatePreferences,
  getUserInQueue,
  startPlayingSong,
  playNextSong,
  stopPlayingSong,
} from '../../../services/actions';
import Notification from '../../Notification';
import NotificationIcon from '../svgs/NotificationIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {useTranslation} from 'react-i18next';
// import NotificationAudioHigh from '../assets/notification-high.mp3';
// import NotificationAudioLow from '../assets/notification-low.mp3';
// import NotificationAudioMedium from '../assets/notification-medium.mp3';
import Button from '@material-ui/core/Button';
import DailySummaryIcon from '../svgs/DailySummaryIcon';
import {getOrderSummary} from '../../../services/actions/salesActions';
import CustomDeliveryIcon from '../../../pages/DashboardPage/svgs/CustomDeliveryIcon';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CDN_URL} from '../../../utils/constants';
import {useHistory} from 'react-router';
import moment from 'moment';
import JukeBoxIcon from '../svgs/JukeBoxIcon';

function HotMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();

  const [ringNotification, setRingNotification] = useState(false);

  const [openNoti, setOpenNoti] = useState(false);
  const [audioErr, setAudioErr] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [preferences, setPreferences] = useState({});

  const [playingSong, setPlayingSong] = useState('');

  const restID = useSelector(
    (state) => state.auth.restData && state.auth.restData.store_id
  );

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  const notificationBadgeCount = useSelector((state) => {
    let count = 0;
    /** Service count */
    if (state.orders.serviceList.length > 0) {
      state.orders.serviceList.forEach((service) => {
        if (!service.attended) {
          count += 1;
        }
      });
    }
    /** Dinein PaymentList */
    if (state.orders.dineinOrders.length > 0) {
      state.orders.dineinOrders.forEach((dinein) => {
        // payment init for cash/card
        if (
          dinein.curr_state === 'payment-initalized' &&
          dinein.payment &&
          dinein.payment.mode &&
          dinein.payment.mode !== 'online'
        ) {
          count += 1;
        }
        // payment captured for online
        if (
          dinein.curr_state === 'payment-captured' &&
          dinein.payment &&
          dinein.payment.mode === 'online'
        ) {
          count += 1;
        }
      });
    }
    /** Takeaway PaymentList */
    if (state.orders.takeawayOrders.length > 0) {
      state.orders.takeawayOrders.forEach((takeaway) => {
        if (
          takeaway.curr_state === 'payment-initalized' &&
          takeaway.initialized_by === 'user'
        ) {
          count += 1;
        }
      });
    }
    /** Hotel PaymentList */
    if (state.orders.hotelOrders.length > 0) {
      state.orders.hotelOrders.forEach((hotel) => {
        if (
          hotel.curr_state === 'payment-initalized' &&
          hotel.initialized_by === 'user'
        ) {
          count += 1;
        }
      });
    }
    /** QSR PaymentList */
    if (state.orders.qsrOrders.length > 0) {
      state.orders.qsrOrders.forEach((qsr) => {
        if (
          qsr.curr_state === 'payment-initalized' &&
          qsr.initialized_by === 'user'
        ) {
          count += 1;
        }
      });
    }
    /** Delivery AcceptList */
    if (state.orders.deliveryOrders.length > 0) {
      state.orders.deliveryOrders.forEach((delivery) => {
        if (delivery.next_states[0] === 'acknowledged') {
          count += 1;
        }
      });
    }

    /** Online AcceptList */
    if (state.orders.onlineOrders.length > 0) {
      state.orders.onlineOrders.forEach((online) => {
        if (online.next_states[0] === 'Acknowledged') {
          count += 1;
        }
      });
    }

    /** Online CancelList */
    if (state.orders.onlineOrders.length > 0) {
      state.orders.onlineOrders.forEach((online) => {
        if (online.canceled) {
          count += 1;
        }
      });
    }

    /** New List */
    if (state.orders.newDinein) {
      count += 1;
    }
    if (state.orders.newTakeaway) {
      count += 1;
    }
    if (state.orders.newHotel) {
      count += 1;
    }
    if (state.orders.newQSR) {
      count += 1;
    }
    if (
      state.orders.deliveryNPOrders &&
      state.orders.deliveryNPOrders.length > 0
    ) {
      count += state.orders.deliveryNPOrders.length;
    }
    if (
      state.orders.deliveryMultiDropNPOrders &&
      state.orders.deliveryMultiDropNPOrders.length > 0
    ) {
      count += state.orders.deliveryMultiDropNPOrders.length;
    }
    return count;
  });

  const songs = useSelector((state) => state.jukebox.songsQueue);
  const currentSong = useSelector((state) => state.jukebox.currentSong);

  useEffect(() => {
    if (
      currentSong &&
      currentSong.current_playing &&
      currentSong.music_id &&
      currentSong.music_id.url
    ) {
      setPlayingSong(currentSong.music_id.url);
    } else {
      setPlayingSong('');
    }
  }, [currentSong]);

  useEffect(() => {
    const noti = document.getElementById('jukebox-wtf');
    if (playingSong) {
      try {
        noti.play();
      } catch (err) {
        console.log(err);
        setAudioErr(true);
      }
    } else {
      try {
        noti.pause();
      } catch (err) {
        console.log(err);
        setAudioErr(true);
      }
    }
  }, [playingSong]);

  useEffect(() => {
    if (notificationBadgeCount) {
      setRingNotification(true);
    } else {
      setRingNotification(false);
    }
  }, [notificationBadgeCount]);

  useEffect(() => {
    if (openNoti) {
      setRingNotification(false);
    }
  }, [openNoti]);

  const pref = useSelector((state) => state.pref.preferences);
  const orderTypes = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.order_types
      ? state.auth.restaurant.order_types
      : []
  );

  useEffect(() => {
    if (delivery || !delivery) {
      setPreferences(pref);
    }
  }, [pref, delivery]);

  const handleJukeBoxClick = () => {
    if (currentSong && currentSong.current_playing) {
      dispatch(stopPlayingSong());
    } else {
      dispatch(startPlayingSong());
    }
  };

  const handleNotificationClick = () => {
    if (notificationBadgeCount > 0) {
      setOpenNoti(true);
    }
  };

  useEffect(() => {
    const noti = document.getElementById('notification-audio-wtf');
    if (ringNotification) {
      try {
        noti.play();
      } catch (err) {
        console.log(err);
        setAudioErr(true);
      }
    } else {
      try {
        noti.pause();
      } catch (err) {
        console.log(err);
        setAudioErr(true);
      }
    }
  }, [ringNotification]);

  const handleSummaryClick = () => {
    const fromDate = moment().startOf('day').add(3, 'hours').utc().unix();
    const toDate = moment().endOf('day').add(3, 'hours').utc().unix();
    dispatch(getOrderSummary(fromDate, toDate));
  };

  const handleDeliverySupport = () => {
    setDelivery(!delivery);
  };

  const handleRefresh = () => {
    switch (history.location.pathname) {
      case '/qms':
        dispatch(fetchSource(restID, dashboardAccess));
        dispatch(getActivetables(restID));
        dispatch(getUserInQueue(restID));
        break;
      default:
        dispatch(fetchSource(restID, dashboardAccess));
        break;
    }
  };

  const icons = [
    {
      component: <CustomDeliveryIcon />,
      handleClick: handleDeliverySupport,
      active: orderTypes.includes('delivery'),
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      component: <DailySummaryIcon />,
      handleClick: handleSummaryClick,
      active: true,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      component: <JukeBoxIcon size={'24'} />,
      handleClick: handleJukeBoxClick,
      active: songs && songs.length > 0,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      component: <NotificationIcon />,
      handleClick: handleNotificationClick,
      count: notificationBadgeCount,
      active: true,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      component: <RefreshIcon />,
      handleClick: handleRefresh,
      active: true,
      visible: true,
    },
  ];

  const NotificationAudio = useSelector((state) => {
    switch (
      state.pref.preferences &&
      state.pref.preferences.notification_volume
    ) {
      case 'low':
        return `${CDN_URL}/notification/notification-low.mp3`;
      case 'medium':
        return `${CDN_URL}/notification/notification-medium.mp3`;
      default:
        return `${CDN_URL}/notification/notification-high.mp3`;
    }
  });

  return (
    <div className={classes.iconFlex}>
      <audio id="notification-audio-wtf" src={NotificationAudio} loop></audio>
      <audio
        id="jukebox-wtf"
        src={playingSong}
        onEnded={() => dispatch(playNextSong())}
      ></audio>
      <Notification open={openNoti} handleClose={() => setOpenNoti(false)} />
      {icons
        .filter((a) => a.visible)
        .map(
          (icon, index) =>
            icon.active && (
              <IconButton key={index} onClick={icon.handleClick}>
                <Badge
                  className={classes.badge}
                  badgeContent={icon.count ? icon.count : 0}
                >
                  {icon.component}
                </Badge>
              </IconButton>
            )
        )}
      <Dialog
        open={audioErr}
        maxWidth={'sm'}
        onClose={() => setAudioErr(false)}
      >
        <DialogTitle>{t('New notification received')}</DialogTitle>
        <DialogContent>
          {t('Autoplay for notification sound is disabled, click ok to enable')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAudioErr(false)}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={delivery} width={'sm'} onClose={handleDeliverySupport}>
        <DialogTitle>{t('Delivery')}</DialogTitle>
        <DialogContent>
          <div className={classes.radioGroup}>
            <FormControlLabel
              control={
                <Switch
                  checked={preferences.delivery && preferences.delivery.active}
                  color="primary"
                  size="small"
                  disabled={
                    preferences.delivery &&
                    !(
                      preferences.delivery.max_radius ||
                      preferences.delivery.value_for_less_than_4km ||
                      preferences.delivery.value_for_more_than_4km
                    )
                  }
                  onChange={() => {
                    const temp = {...preferences};
                    temp['delivery']['active'] =
                      !preferences['delivery']['active'];
                    dispatch(updatePreferences(temp));
                  }}
                />
              }
              label={t('Status')}
            />
          </div>
          {preferences.delivery &&
            !(
              preferences.delivery.max_radius ||
              preferences.delivery.value_for_less_than_4km ||
              preferences.delivery.value_for_more_than_4km
            ) && (
              <div className={classes.errorMessage}>
                *{' '}
                {t(
                  'Delivery preferences not initiated from merchant dashboard'
                )}
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeliverySupport()}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HotMenu;

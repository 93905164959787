/* eslint-disable valid-jsdoc */
import {printKOT, printBill, setLoading} from '../miscActions';
import {removeDineInQueue} from '../dineActions';
import {removeHoldQueue} from '../holdActions';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const setCurrentSelectedOrder = (id, type) => ({
  type: 'SET_CURRENT_SELECTED_ORDER',
  payload: {_id: id, type: type},
});

export const setOrderLoading = (status) => ({
  type: 'ORDER_LOADING',
  payload: status,
});

export const saveOrders = (order, doNotPrint) => {
  return async (dispatch) => {
    switch (order.__type) {
      case 'dinein':
        if (!doNotPrint) {
          dispatch(printDineInKots(order));
        }
        dispatch({type: 'ADD_TO_DINEIN_ORDERS', payload: order});
        break;
      case 'takeaway':
        dispatch({type: 'ADD_TO_TAKEAWAY_ORDERS', payload: order});
        break;
      case 'delivery':
        if (order.curr_state === 'delivery-not-possible') {
          await dispatch(removeOrders(order));
          await dispatch({
            type: 'ADD_TO_NOT_POSSIBLE_DELIVERY',
            payload: order,
          });
        } else {
          await dispatch(removeOrders(order));
          await dispatch({type: 'ADD_TO_DELIVERY_ORDERS', payload: order});
        }
        break;
      case 'hotel':
        dispatch({type: 'ADD_TO_HOTEL_ORDERS', payload: order});
        break;
      case 'qsr':
        dispatch({type: 'ADD_TO_QSR_ORDERS', payload: order});
        break;
      case 'online':
        dispatch({type: 'ADD_TO_ONLINE_ORDERS', payload: order});
        break;
      case 'group-delivery':
        if (order.curr_state === 'delivery-not-possible') {
          await dispatch(removeOrders(order));
          await dispatch({
            type: 'ADD_TO_NOT_POSSIBLE_MULTIDROP_DELIVERY',
            payload: order,
          });
        } else {
          await dispatch(removeOrders(order));
          await dispatch({type: 'ADD_TO_MULTIDROP_ORDERS', payload: order});
        }
        break;
      default:
        break;
    }
  };
};

export const saveMultiDropOrders = (order) => ({
  type: 'UPDATE_MULTIDROP_ORDER',
  payload: order,
});

export const removeOrders = (order) => {
  return (dispatch) => {
    switch (order.__type) {
      case 'dinein':
        dispatch({type: 'REMOVE_DINEIN_ORDERS', payload: order._id});
        break;
      case 'takeaway':
        dispatch({type: 'REMOVE_TAKEAWAY_ORDERS', payload: order._id});
        break;
      case 'delivery':
        dispatch({type: 'REMOVE_DELIVERY_ORDERS', payload: order._id});
        dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY',
          payload: order._id,
        });
        break;
      case 'hotel':
        dispatch({type: 'REMOVE_HOTEL_ORDERS', payload: order._id});
        break;
      case 'qsr':
        dispatch({type: 'REMOVE_QSR_ORDERS', payload: order._id});
        break;
      case 'online':
        dispatch({type: 'REMOVE_ONLINE_ORDERS', payload: order._id});
        break;
      case 'group-delivery':
        dispatch({type: 'REMOVE_MULTIDROP_ORDERS', payload: order._id});
        dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_MULTIDROP_DELIVERY',
          payload: order._id,
        });
        break;
      default:
        break;
    }
  };
};

export const getStoreLoyalty = () => {
  return (dispatch, getState) => {
    const restaurantID = getState().auth.restData.store_id;
    dispatch(setOrderLoading(true));
    http
      .get(`${API_URL}/loyalty/${restaurantID}`)
      .then((res) => {
        dispatch({type: 'INIT_STORE_LOYALTY', payload: res.data});
        dispatch(setOrderLoading(false));
      })
      .catch(() => {
        dispatch(setOrderLoading(false));
        toast.error('unable to get Vouchers');
      });
  };
};

export const getOrders = () => {
  return (dispatch, getState) => {
    dispatch({type: 'RESET_ORDERS'});
    const id = getState().auth.restData.store_id;
    dispatch(setOrderLoading(true));
    http
      .get(`${API_URL}/orders/store/${id}`)
      .then(async (res) => {
        if (res.data.length > 0) {
          res.data.forEach((order) => {
            if (
              order.active &&
              (!order.canceled || order.__type === 'online')
            ) {
              dispatch(saveOrders(order));
            }
          });
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const getMultiDropOrders = () => {
  return (dispatch, getState) => {
    const id = getState().auth.restData.store_id;
    dispatch(setOrderLoading(true));
    http
      .get(`${API_URL}/order_group/store/${id}`)
      .then(async (res) => {
        if (res.data.length > 0) {
          res.data.forEach((order) => {
            dispatch(saveOrders(order));
          });
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const getServices = () => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .get(`${API_URL}/services`)
      .then((res) => {
        dispatch({type: 'INIT_SERVICE_LIST', payload: res.data});
        dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const attendService = (serviceID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/services/${serviceID}/attend`)
      .then(() => {
        dispatch(setOrderLoading(false));
        dispatch(removeServiceFromList(serviceID));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const removeServiceFromList = (serviceID) => ({
  type: 'REMOVE_SERVICE_FROM_LIST',
  payload: serviceID,
});

export const createDineinOrder = (data) => {
  return (dispatch, getState) => {
    dispatch(setOrderLoading(true));
    const restID = getState().auth.restaurant._id;
    const order = {};
    order['items'] = data.items;
    order['__type'] = data.type;
    order['table_id'] = data.table_id;
    order['store_id'] = restID;
    http
      .post(`${API_URL}/orders`, order)
      .then(async (res) => {
        await dispatch(removeDineInQueue(data.table_id));
        await dispatch(saveOrders(res.data, true));
        await dispatch(setCurrentSelectedOrder(res.data._id, res.data.__type));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const createTakeawayOrder = (data) => {
  return (dispatch, getState) => {
    dispatch(setOrderLoading(true));
    const restID = getState().auth.restaurant._id;
    const order = {};
    order['items'] = data.items;
    order['__type'] = data.type;
    if (data.table_no) {
      order['table_id'] = data._id;
    }
    order['store_id'] = restID;
    order['userDetails'] = {};
    if (data.user_name && data.user_name !== '') {
      order['userDetails']['name'] = data.user_name;
    }
    if (data.user_phone && data.user_phone !== '') {
      order['userDetails']['phone'] = data.user_phone;
    }
    if (data.type === 'delivery') {
      order['userDetails'] = {
        phone: data.phone,
        name: data.name,
        address: data.address,
      };
    }
    http
      .post(`${API_URL}/orders`, order)
      .then(async (res) => {
        await dispatch(removeHoldQueue(data._id));
        if (data.type !== 'delivery') {
          await dispatch(saveOrders(res.data));
          await dispatch(
            setCurrentSelectedOrder(res.data._id, res.data.__type)
          );
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const createMultiDropOrder = (req) => {
  return (dispatch, getState) => {
    dispatch(setOrderLoading(true));
    const restID = getState().auth.restaurant._id;
    const order = [];
    if (req.data && req.data.length > 0) {
      req.data.forEach((obj) => {
        order.push({
          items: obj.items,
          __type: 'delivery',
          store_id: restID,
          userDetails: {
            phone: obj.phone,
            name: obj.name,
            address: obj.address,
          },
        });
      });
    }

    http
      .post(`${API_URL}/order_group/${restID}/delivery`, order)
      .then(async (res) => {
        await dispatch(removeHoldQueue(req._id));
        await dispatch(saveOrders(res.data));
        await dispatch(setCurrentSelectedOrder(res.data._id, res.data.__type));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const addUser = (id, phone) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/orders/${id}`, {user_phone: phone})
      .then(() => {
        dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const addKot = (id, item) => {
  return (dispatch, getState) => {
    dispatch(setOrderLoading(true));
    const restID = getState().auth.restaurant._id;
    const order = {};
    order['items'] = item;
    order['store_id'] = restID;
    http
      .put(`${API_URL}/orders/${id}`, order)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(
            `${err.response.data.name ? err.response.data.name : ''} ${
              err.response.data.message
            }`
          );
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateKotItem = (id, item, complementary) => {
  return (dispatch, getState) => {
    dispatch(setOrderLoading(true));
    const restID = getState().auth.restaurant._id;
    const order = {};
    order['items'] = item;
    order['complementary'] = complementary;
    order['store_id'] = restID;
    http
      .put(`${API_URL}/orders/${id}`, order)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateKotItemPrepared = (kotID, item) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/kitchen/kot/${kotID}/${item._id}`, item)
      .then(async () => {
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const addDiscount = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/orders/${id}`, {discount: data})
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const addPax = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/pax`, {pax: data})
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const addWaiter = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/waiter`, {waiter_id: data})
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const initPayOrder = (id, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const data = {};
    if (reason) {
      data['bill_print_reason'] = reason;
    }
    http
      .post(`${API_URL}/orders/${id}/init/pay`, data)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const modifyOrder = (id, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/modify`, {
        modification_reason: reason,
      })
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        dispatch(setOrderLoading(false));
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
      });
  };
};

export const kotNotified = (id, KOTID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/kot/${KOTID}`, {
        notified: false,
      })
      .then(() => {
        dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        dispatch(setOrderLoading(false));
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
      });
  };
};

export const sendEbill = (id, phone) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/ebill`, {
        user_phone: phone,
      })
      .then(() => {
        dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        dispatch(setOrderLoading(false));
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
      });
  };
};

export const payOrder = (
  id,
  mode,
  walletType,
  settle,
  partPaymnet,
  khataRemarks
) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const data = {};
    data['payment_mode'] = mode;
    if (walletType && walletType !== '') {
      data['wallet'] = walletType;
    }
    // console.log('partPayement', partPaymnet, typeof partPaymnet);
    if (typeof partPaymnet === 'string' || typeof partPaymnet === 'number') {
      data['amount'] = parseInt(partPaymnet);
    }
    if (khataRemarks && mode === 'khata') {
      data['khata_remark'] = khataRemarks;
    }
    http
      .post(`${API_URL}/orders/${id}/pay`, data)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
        if (res.data.__type !== 'dinein') {
          await dispatch(removeHoldQueue(id));
        }
        if (settle && res.data.paid) {
          await dispatch(settleOrder(id));
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateServiceCharge = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/orders/${id}`, {disable_service_charge: data})
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateUserName = (orderID, userID, name) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/users?id=${userID}`, {fName: name})
      .then(async (res) => {
        await dispatch(setOrderLoading(false));
        await dispatch(updateOrder(orderID, true));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const createBillSplit = (id, data, successCB) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/split_bill`, data)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await successCB();
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const printOrder = (orderID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const data = {
      bill_print: true,
    };
    if (reason) {
      data['bill_print_reason'] = reason;
    }
    http
      .post(`${API_URL}/orders/${orderID}/print`, data)
      .then(() => {
        dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const capturePayment = (id, printKot, settle) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/capture`)
      .then(async (res) => {
        if (printKot) {
          await dispatch(printKOTandBillOrder(res.data));
          await dispatch(removeOrders(res.data));
        } else {
          await dispatch(printOrderBill(res.data));
          await dispatch(removeHoldQueue(id));
        }
        await dispatch(setOrderLoading(false));
        if (settle) {
          await dispatch(settleOrder(id));
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const settleOrder = (id) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/settle`)
      .then(async (res) => {
        await dispatch(removeOrders(res.data));
        await dispatch(removeHoldQueue(id));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const deliverySupport = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/initExtDelivery`)
      .then(async (res) => {
        toast.success(
          "Looking for runner to fulfill your delivery. We'll notify you when a runner is assigned"
        );
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const localLastMileSupport = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/local_lastmile/${orderID}/searchRiders`)
      .then(async (res) => {
        toast.success(
          "Looking for local runner to fulfill your delivery. We'll notify you when a rider accepts"
        );
        await dispatch(saveOrders(res.data.newOrder, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const retryMutiDropDeliverySupport = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/order_group/${orderID}/initExtDelivery`)
      .then(async (res) => {
        toast.success(
          "Looking for runner to fulfill your delivery. We'll notify you when a runner is assigned"
        );
        await dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_MULTIDROP_DELIVERY',
          payload: orderID,
        });
        await dispatch(saveOrders(res.data));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const lastMiletoSelf = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/initSelfDelivery`)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY',
          payload: orderID,
        });
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelOrder = (orderID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteOrder = {
      canceled: true,
      reason: reason,
    };
    http
      .delete(`${API_URL}/orders/${orderID}`, {data: deleteOrder})
      .then(async (res) => {
        await dispatch(removeOrders(res.data));
        await dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY',
          payload: orderID,
        });
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelMultiDropOrder = (orderID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteOrder = {
      canceled: true,
      reason: reason,
    };
    http
      .delete(`${API_URL}/order_group/${orderID}`, {data: deleteOrder})
      .then(async (res) => {
        await dispatch(removeOrders(res.data));
        await dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_MULTIDROP_DELIVERY',
          payload: orderID,
        });
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelUPOrder = (orderID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteOrder = {
      new_status: 'Cancelled',
      message: reason,
    };
    http
      .put(`${API_URL}/up/order/${orderID}`, deleteOrder)
      .then(async () => {
        await dispatch(removeOrders({__type: 'online', _id: orderID}));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const ackUPCancel = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/up/order/${orderID}/active`)
      .then(async () => {
        await dispatch(removeOrders({__type: 'online', _id: orderID}));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelKot = (orderID, kotID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteKot = {
      kots: [
        {
          _id: kotID,
          canceled: true,
        },
      ],
      reason: reason,
    };
    http
      .delete(`${API_URL}/orders/${orderID}`, {data: deleteKot})
      .then(async (res) => {
        await dispatch(saveOrders(res.data.updatedOrder, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelKOTItem = (orderID, kotID, itemID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteItem = {
      kots: [
        {
          _id: kotID,
          items: [
            {
              _id: itemID,
              canceled: true,
            },
          ],
        },
      ],
      reason: reason,
    };
    http
      .delete(`${API_URL}/orders/${orderID}`, {data: deleteItem})
      .then(async (res) => {
        await dispatch(saveOrders(res.data.updatedOrder, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateOrder = (id, loading) => {
  return (dispatch, getState) => {
    const tableData = [
      ...getState().dine.tableData,
      ...getState().dine.hotelData,
    ];
    if (loading) {
      dispatch(setOrderLoading(true));
    }
    http
      .get(`${API_URL}/orders/${id}`)
      .then(async (res) => {
        if (res.data.active && !res.data.canceled) {
          if (res.data.group_order) {
            await dispatch(saveMultiDropOrders(res.data));
            if (res.data.bill_print) {
              await dispatch(printOrderBill(res.data));
            }
          } else if (
            res.data.__type === 'dinein' ||
            res.data.__type === 'hotel'
          ) {
            let tableIndex = -1;
            res.data.tables &&
              res.data.tables.length > 0 &&
              res.data.tables.forEach((table) => {
                const index = tableData.findIndex((a) => a._id === table._id);
                if (index > -1) {
                  tableIndex = index;
                }
              });
            if (tableIndex > -1) {
              await dispatch(saveOrders(res.data));
              if (res.data.bill_print) {
                await dispatch(printOrderBill(res.data));
              }
            }
          } else {
            await dispatch(saveOrders(res.data));
            if (res.data.bill_print) {
              await dispatch(printOrderBill(res.data));
            }
          }
        }
        if (res.data.settled || res.data.canceled) {
          await dispatch(removeOrders(res.data));
        }
        await dispatch(setOrderLoading(false));
      })
      .catch(() => {
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateMultiDropOrder = (id) => {
  return (dispatch) => {
    http
      .get(`${API_URL}/order_group/${id}/details`)
      .then(async (res) => {
        if (res.data.active) {
          if (res.data.curr_state !== 'delivery-not-possible') {
            await dispatch({
              type: 'REMOVE_FROM_NOT_POSSIBLE_MULTIDROP_DELIVERY',
              payload: id,
            });
          }
          await dispatch(saveOrders(res.data));
        } else {
          await dispatch({
            type: 'REMOVE_FROM_NOT_POSSIBLE_MULTIDROP_DELIVERY',
            payload: id,
          });
          await dispatch(removeOrders(res.data));
        }
      })
      .catch(() => {});
  };
};

export const updateUPorder = (id, state) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/up/order/${id}`, {new_status: state})
      .then(async (res) => {
        if (res.data && res.data.message) {
          toast.info(res.data.message);
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const acknowledgeOrder = (id) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/orders/${id}/ack`)
      .then(async (res) => {
        if (res.data.active) {
          await dispatch(saveOrders(res.data, true));
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const acknowledgeUPOrder = (id) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/up/order/${id}`, {new_status: 'Acknowledged'})
      .then(async (res) => {
        if (res.data && res.data.message) {
          toast.info(res.data.message);
        }
        await dispatch(removeOrders({__type: 'online', _id: id}));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const deliveryOrder = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/delivery/${id}/state`, data)
      .then(async (res) => {
        if (res.data.updatedOrder) {
          await dispatch(saveOrders(res.data.updatedOrder));
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const sendOrderSMS = (type, orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/stores/msg/user/${orderID}?type=${type}`, {})
      .then(() => {
        toast.error('Message sent successfully');
        dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

/** Notification */
export const resetNotification = (type) => {
  return (dispatch) => {
    switch (type) {
      case 'dinein':
        dispatch({type: 'NEW_DINEIN_ORDERS', payload: false});
        break;
      case 'takeaway':
        dispatch({type: 'NEW_TAKEAWAY_ORDERS', payload: false});
        break;
      case 'hotel':
        dispatch({type: 'NEW_HOTEL_ORDERS', payload: false});
        break;
      case 'qsr':
        dispatch({type: 'NEW_QSR_ORDERS', payload: false});
        break;
      default:
        break;
    }
  };
};

/** Print bill*/
export const printParticularKOT = (kot, tableNO, orderID) => {
  return (dispatch) => {
    const filteredItemData = [];
    kot.items &&
      kot.items.length > 0 &&
      kot.items.forEach((item) => {
        const temp = {};
        temp['item_name'] = item.name;
        temp['base_price'] = item.item_base_price;
        temp['net_amount'] = item.net_amount;
        temp['addons'] = item.addons;
        if (item.addons.length > 0) {
          item.addons.forEach((addons) => {
            temp['base_price'] += addons.total_amount;
          });
        }
        temp['qty'] = item.qty;
        temp['note'] = item.note;
        temp['kitchen_no'] = item.kitchen_number;
        temp['cancelled'] = item.canceled;
        temp['cancelled_print'] = item.canceled_print;
        filteredItemData.push(temp);
      });
    dispatch(
      printKOT(
        tableNO,
        filteredItemData,
        kot.daily_number,
        kot.canceled,
        orderID,
        kot._id,
        kot.createdAt,
        kot.canceled_print
      )
    );
  };
};

export const printDineInKots = (order) => {
  return (dispatch) => {
    order.bill.kots &&
      order.bill.kots.forEach((kot) => {
        if (!kot.printed && kot._id) {
          if (order.tables && order.tables.length > 0) {
            dispatch(
              printParticularKOT(kot, order.tables[0].table_no, order._id)
            );
          } else {
            dispatch(
              printParticularKOT(kot, order.__type.toUpperCase(), order._id)
            );
          }
        }
      });
  };
};

export const splitGSTBill = (orderID, kotPrint, orderFor) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/split_bill/gst`)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.forEach((order, index) => {
            if (kotPrint) {
              dispatch(
                printTakeawayBill(
                  {...order, _id: `${order._id}-${index}`},
                  orderFor
                )
              );
            } else {
              dispatch(
                printOrderBill({...order, _id: `${order._id}-${index}`})
              );
            }
          });
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const printOrderBill = (order) => {
  return (dispatch, getState) => {
    const GSTIN =
      getState().auth.restaurant &&
      getState().auth.restaurant.preferences &&
      getState().auth.restaurant.preferences.GSTIN;
    console.log('GSTIN', GSTIN);
    if (!order.GSTIN && GSTIN && GSTIN.length > 1) {
      dispatch(splitGSTBill(order._id));
    } else {
      const billItems = [];
      const partition =
        order.split_bill && order.split_bill.partition
          ? order.split_bill.partition
          : 1;
      if (order.bill.kots && order.bill.kots.length > 0) {
        order.bill.kots.forEach((kot) => {
          kot.items &&
            kot.items.length > 0 &&
            kot.items.forEach((item) => {
              const temp = {};
              let itemBasePrice = 0;
              temp['item_name'] = item.name;
              itemBasePrice = item.item_base_price / partition;
              temp['net_amount'] = (item.net_amount / partition).toFixed(2);
              temp['addons'] = item.addons;
              if (item.addons.length > 0) {
                item.addons.forEach((addons) => {
                  itemBasePrice += addons.total_amount / partition;
                });
              }
              temp['base_price'] = itemBasePrice.toFixed(2);
              temp['qty'] = item.qty;
              temp['note'] = item.note;
              temp['kitchen_no'] = item.kitchen_number;
              temp['cancelled'] = item.canceled;
              temp['cancelled_print'] = item.canceled_print;
              billItems.push(temp);
            });
        });
      }
      const businessName = getState().auth.restaurant.business_name;
      const name = getState().auth.restaurant.name;
      const addressObject = getState().auth.restaurant.address;
      const address =
        addressObject &&
        `${addressObject.line1}, ${addressObject.line2}, ${addressObject.city.name} - ${addressObject.pincode}`;
      let gstIN = '';
      if (order.GSTIN) {
        gstIN = order.GSTIN;
      } else if (GSTIN && GSTIN.length > 0 && GSTIN[0].GSTIN) {
        gstIN = GSTIN[0].GSTIN;
      }
      const billData = {
        restaurant_name: businessName ? {en: businessName} : name,
        gst_no: gstIN,
        restaurant_address: address,
        table_no: order.__type.toUpperCase(),
        bill_no: order.order_number,
        bill_data: billItems,
        sub_total: `${(order.bill.sub_total_amount / partition).toFixed(2)}`,
        taxes: [],
        charges: [],
        discount: order.bill.total_discount / partition,
        grand_total: `${(order.bill.round_off_total_amount / partition).toFixed(
          2
        )}`,
        currency: order.bill.currency && order.bill.currency.symbol,
        timestamp: order.createdAt,
      };
      if (order.bill.taxes && order.bill.taxes.length > 0) {
        const tempTax = [];
        order.bill.taxes.forEach((tax) => {
          tempTax.push({
            amount: (tax.amount / partition).toFixed(2),
            name: {...tax.name},
            value: (tax.value / partition).toFixed(2),
            _id: tax._id,
          });
        });
        billData['taxes'] = [...billData.taxes, ...tempTax];
      }
      if (order.bill.charges && order.bill.charges.length > 0) {
        const tempCharge = [];
        order.bill.charges.forEach((charge) => {
          tempCharge.push({
            amount: (charge.amount / partition).toFixed(2),
            name: {en: charge.name},
            value: (
              ((charge.amount / order.bill.sub_total_amount) * 100) /
              partition
            ).toFixed(2),
          });
        });
        billData['taxes'] = [...billData.taxes, ...tempCharge];
      }
      if (order.tables && order.tables.length > 0 && order.tables[0].table_no) {
        const temp = [];
        order.tables.forEach((table) => {
          temp.push(table.table_no);
        });
        billData['table_no'] =
          partition > 1 ? `${temp.join(', ')} (split)` : temp.join(', ');
      }
      dispatch(printBill(billData, order._id, partition));
    }
  };
};

export const printKOTandBillOrder = (order, printFor) => {
  return (dispatch, getState) => {
    const GSTIN =
      getState().auth.restaurant &&
      getState().auth.restaurant.preferences &&
      getState().auth.restaurant.preferences.GSTIN;
    const billItems = [];
    order.bill.kots &&
      order.bill.kots.length > 0 &&
      order.bill.kots.forEach((kot) => {
        const filteredItemData = [];
        kot.items &&
          kot.items.length > 0 &&
          kot.items.forEach((item) => {
            const temp = {};
            temp['item_name'] = item.name;
            temp['base_price'] = item.item_base_price;
            temp['net_amount'] = item.net_amount;
            temp['addons'] = item.addons;
            temp['qty'] = item.qty;
            temp['note'] = item.note;
            temp['kitchen_no'] = item.kitchen_number;
            temp['cancelled'] = item.canceled;
            temp['cancelled_print'] = item.canceled_print;
            billItems.push(temp);
            filteredItemData.push(temp);
          });
        if (!kot.printed) {
          if (order.tables && order.tables.length > 0) {
            dispatch(
              printKOT(
                order.tables[0].table_no,
                filteredItemData,
                kot.daily_number,
                kot.canceled,
                order._id,
                kot._id,
                kot.createdAt
              )
            );
          } else if (printFor) {
            dispatch(
              printKOT(
                `${order.__type.toUpperCase()} - ${printFor}`,
                filteredItemData,
                kot.daily_number,
                kot.canceled,
                order._id,
                kot._id,
                kot.createdAt
              )
            );
          } else {
            dispatch(
              printKOT(
                order.__type.toUpperCase(),
                filteredItemData,
                kot.daily_number,
                kot.canceled,
                order._id,
                kot._id,
                kot.createdAt
              )
            );
          }
        }
      });
    if (!order.GSTIN && GSTIN && GSTIN.length > 1) {
      dispatch(splitGSTBill(order._id, true, printFor));
    } else {
      dispatch(printTakeawayBill(order, printFor));
    }
  };
};

export const printTakeawayBill = (order, printFor) => {
  return (dispatch, getState) => {
    const GSTIN =
      getState().auth.restaurant &&
      getState().auth.restaurant.preferences &&
      getState().auth.restaurant.preferences.GSTIN;
    const businessName = getState().auth.restaurant.business_name;
    const name = getState().auth.restaurant.name;
    const addressObject = getState().auth.restaurant.address;
    const address =
      addressObject &&
      `${addressObject.line1}, ${addressObject.line2}, ${addressObject.city.name} - ${addressObject.pincode}`;
    let gstIN = '';
    if (order.GSTIN) {
      gstIN = order.GSTIN;
    } else if (GSTIN && GSTIN.length > 0 && GSTIN[0].GSTIN) {
      gstIN = GSTIN[0].GSTIN;
    }
    const billItems = [];
    if (order.bill.kots && order.bill.kots.length > 0) {
      order.bill.kots.forEach((kot) => {
        kot.items &&
          kot.items.length > 0 &&
          kot.items.forEach((item) => {
            const temp = {};
            let itemBasePrice = 0;
            temp['item_name'] = item.name;
            itemBasePrice = item.item_base_price;
            temp['net_amount'] = item.net_amount.toFixed(2);
            temp['addons'] = item.addons;
            if (item.addons.length > 0) {
              item.addons.forEach((addons) => {
                itemBasePrice += addons.total_amount;
              });
            }
            temp['base_price'] = itemBasePrice.toFixed(2);
            temp['qty'] = item.qty;
            temp['note'] = item.note;
            temp['kitchen_no'] = item.kitchen_number;
            temp['cancelled'] = item.canceled;
            temp['cancelled_print'] = item.canceled_print;
            billItems.push(temp);
          });
      });
    }
    const billData = {
      restaurant_name: businessName ? {en: businessName} : name,
      gst_no: gstIN,
      restaurant_address: address,
      table_no: `${order.__type.toUpperCase()}${
        printFor ? ` - ${printFor}` : ''
      }`,
      bill_no: order.order_number,
      bill_data: billItems,
      sub_total:
        typeof order.bill.sub_total_amount === 'string'
          ? order.bill.sub_total_amount
          : order.bill.sub_total_amount.toFixed(2),
      taxes: order.bill.taxes,
      charges: [],
      discount: order.bill.total_discount,
      grand_total: order.bill.round_off_total_amount.toFixed(2),
      currency: order.bill.currency && order.bill.currency.symbol,
      timestamp: order.createdAt,
    };
    if (order.bill.charges && order.bill.charges.length > 0) {
      const tempCharge = [];
      order.bill.charges.forEach((charge) => {
        tempCharge.push({
          amount: charge.amount.toFixed(2),
          name: {en: charge.name},
          value: ((charge.amount / order.bill.sub_total_amount) * 100).toFixed(
            2
          ),
        });
      });
      billData['taxes'] = [...billData.taxes, ...tempCharge];
    }
    if (order.tables && order.tables.length > 0 && order.tables[0].table_no) {
      billData['table_no'] = order.tables[0].table_no;
    }
    dispatch(printBill(billData, order._id));
  };
};

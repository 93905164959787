/* eslint-disable camelcase */
import _ from 'lodash';
import * as actionTypes from '../../actionTypes';

const initState = {
  currentSelectedHold: {},
  holdQueue: [],
  deliveryUsers: [],
  subscribers: [],
  activeMultiDelivery: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'CURRENT_SELECTED_HOLD':
      let tempMultiDelivery = null;
      if (action.payload.type === 'delivery-multiple') {
        tempMultiDelivery = '0';
      }
      return {
        ...state,
        currentSelectedHold: action.payload,
        activeMultiDelivery: tempMultiDelivery,
      };
    case 'CREATE_HOLD_QUEUE': {
      const tempHoldQueue = [...state.holdQueue];
      tempHoldQueue.push(action.payload);
      let tempMultiDelivery = null;
      if (action.payload.type === 'delivery-multiple') {
        tempMultiDelivery = '0';
      }
      return {
        ...state,
        holdQueue: tempHoldQueue,
        currentSelectedHold: action.payload,
        activeMultiDelivery: tempMultiDelivery,
      };
    }
    case 'ADD_NEW_DATA_HOLD_QUEUE': {
      const tempAddMultiQueue = [...state.holdQueue];
      const tempAddMultiQueueIndex = tempAddMultiQueue.findIndex(
        (a) => a._id === action.payload._id
      );
      if (tempAddMultiQueueIndex > -1) {
        tempAddMultiQueue[tempAddMultiQueueIndex] = action.payload;
      }
      return {
        ...state,
        holdQueue: tempAddMultiQueue,
        currentSelectedHold: action.payload,
        activeMultiDelivery: `${action.payload.data.length - 1}`,
      };
    }
    case 'ACTIVE_MULTI_DELIVERY_ACCORDIAN': {
      return {
        ...state,
        activeMultiDelivery: action.payload,
      };
    }
    case 'UPDATE_UD_HOLD_QUEUE': {
      const updateUDHoldQueue = [...state.holdQueue];
      const updateUDCurrentSelectedHold = {...state.currentSelectedHold};
      const index = updateUDHoldQueue.findIndex(
        (a) => a._id === action.payload._id
      );
      if (index > -1) {
        updateUDHoldQueue[index]['name'] = action.payload.name;
        updateUDHoldQueue[index]['phone'] = action.payload.phone;
        updateUDHoldQueue[index]['address'] = action.payload.address;
      }
      updateUDCurrentSelectedHold['name'] = action.payload.name;
      updateUDCurrentSelectedHold['phone'] = action.payload.phone;
      updateUDCurrentSelectedHold['address'] = action.payload.address;
      return {
        ...state,
        holdQueue: updateUDHoldQueue,
        currentSelectedHold: updateUDCurrentSelectedHold,
      };
    }
    case 'UPDATE_MULTIDROP_UD_HOLD_QUEUE': {
      const updateMultiDropUDHoldQueue = [...state.holdQueue];
      const updatemultiDropUDCurrentSelectedHold = {
        ...state.currentSelectedHold,
      };
      const update_index = updateMultiDropUDHoldQueue.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_index > -1) {
        updateMultiDropUDHoldQueue[update_index].data[action.payload.index][
          'name'
        ] = action.payload.name;
        updateMultiDropUDHoldQueue[update_index].data[action.payload.index][
          'phone'
        ] = action.payload.phone;
        updateMultiDropUDHoldQueue[update_index].data[action.payload.index][
          'address'
        ] = action.payload.address;
      }
      updatemultiDropUDCurrentSelectedHold.data[action.payload.index]['name'] =
        action.payload.name;
      updatemultiDropUDCurrentSelectedHold.data[action.payload.index]['phone'] =
        action.payload.phone;
      updatemultiDropUDCurrentSelectedHold.data[action.payload.index][
        'address'
      ] = action.payload.address;
      return {
        ...state,
        holdQueue: updateMultiDropUDHoldQueue,
        currentSelectedHold: updatemultiDropUDCurrentSelectedHold,
      };
    }
    case 'UPDATE_CUSTOMER_NAME': {
      const nameHoldQueue = [...state.holdQueue];
      const nameCurrentSelectedHold = {...state.currentSelectedHold};
      const index = nameHoldQueue.findIndex(
        (a) => a._id === action.payload._id
      );
      if (index > -1) {
        nameHoldQueue[index]['user_name'] = action.payload.user_name;
      }
      nameCurrentSelectedHold['user_name'] = action.payload.user_name;
      return {
        ...state,
        holdQueue: nameHoldQueue,
        currentSelectedHold: nameCurrentSelectedHold,
      };
    }
    case 'UPDATE_CUSTOMER_NUMBER': {
      const numberHoldQueue = [...state.holdQueue];
      const numberCurrentSelectedHold = {...state.currentSelectedHold};
      const index = numberHoldQueue.findIndex(
        (a) => a._id === action.payload._id
      );
      if (index > -1) {
        numberHoldQueue[index]['user_phone'] = action.payload.user_phone;
      }
      numberCurrentSelectedHold['user_phone'] = action.payload.user_phone;
      return {
        ...state,
        holdQueue: numberHoldQueue,
        currentSelectedHold: numberCurrentSelectedHold,
      };
    }
    case 'ADD_ITEM_TO_HOLD_QUEUE':
      const add_item_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );

      const getAddonPrice = (item) => {
        if (item.addons.length === 0) {
          return item.base_price;
        } else {
          let temp = item.base_price;
          item.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };

      const indexItem =
        state.holdQueue[add_item_index] &&
        state.holdQueue[add_item_index].items.findIndex(
          (value) =>
            value.item_id === action.payload.item._id &&
            _.isEqual(value.addons, action.payload.item.addons)
        );

      if (indexItem !== -1 && state.holdQueue[add_item_index]) {
        const item = {
          ...state.holdQueue[add_item_index].items[indexItem],
          qty: state.holdQueue[add_item_index].items[indexItem].qty + 1,
          total_price:
            state.holdQueue[add_item_index].items[indexItem].item_price *
            (state.holdQueue[add_item_index].items[indexItem].qty + 1),
        };
        return {
          ...state,
          holdQueue: [
            ...state.holdQueue.slice(0, add_item_index),
            {
              ...state.holdQueue[add_item_index],
              items: [
                ...state.holdQueue[add_item_index].items.slice(0, indexItem),
                item,
                ...state.holdQueue[add_item_index].items.slice(indexItem + 1),
              ],
            },
            ...state.holdQueue.slice(add_item_index + 1),
          ],
        };
      } else {
        const item = {
          item_id: action.payload.item._id,
          name: action.payload.item.name,
          item_price: getAddonPrice(action.payload.item),
          total_price: getAddonPrice(action.payload.item),
          addons: action.payload.item.addons,
          qty: 1,
          note: '',
        };
        return {
          ...state,
          holdQueue: [
            ...state.holdQueue.slice(0, add_item_index),
            {
              ...state.holdQueue[add_item_index],
              items: [...state.holdQueue[add_item_index].items, item],
            },
            ...state.holdQueue.slice(add_item_index + 1),
          ],
        };
      }
    case 'ADD_ITEM_TO_MULTIDROP_HOLD_QUEUE': {
      const multi_drop_add_item_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );

      const getMultiDropAddonPrice = (item) => {
        if (item.addons.length === 0) {
          return item.base_price;
        } else {
          let temp = item.base_price;
          item.addons.forEach((obj) => {
            obj.addon_items.forEach((addon) => {
              temp = temp + addon.price;
            });
          });
          return temp;
        }
      };

      const multiDropindexItem =
        state.holdQueue[multi_drop_add_item_index] &&
        state.holdQueue[multi_drop_add_item_index].data[action.payload.index] &&
        state.holdQueue[multi_drop_add_item_index].data[
          action.payload.index
        ].items.findIndex(
          (value) =>
            value.item_id === action.payload.item._id &&
            _.isEqual(value.addons, action.payload.item.addons)
        );

      if (
        multiDropindexItem !== -1 &&
        state.holdQueue[multi_drop_add_item_index]
      ) {
        const item = {
          ...state.holdQueue[multi_drop_add_item_index].data[
            action.payload.index
          ].items[multiDropindexItem],
          qty:
            state.holdQueue[multi_drop_add_item_index].data[
              action.payload.index
            ].items[multiDropindexItem].qty + 1,
          total_price:
            state.holdQueue[multi_drop_add_item_index].data[
              action.payload.index
            ].items[multiDropindexItem].item_price *
            (state.holdQueue[multi_drop_add_item_index].data[
              action.payload.index
            ].items[multiDropindexItem].qty +
              1),
        };
        const addData = [...state.holdQueue[multi_drop_add_item_index].data];
        addData[action.payload.index]['items'] = [
          ...addData[action.payload.index].items.slice(0, multiDropindexItem),
          item,
          ...addData[action.payload.index].items.slice(multiDropindexItem + 1),
        ];
        return {
          ...state,
          holdQueue: [
            ...state.holdQueue.slice(0, multi_drop_add_item_index),
            {
              ...state.holdQueue[multi_drop_add_item_index],
              data: addData,
            },
            ...state.holdQueue.slice(multi_drop_add_item_index + 1),
          ],
        };
      } else {
        const item = {
          item_id: action.payload.item._id,
          name: action.payload.item.name,
          item_price: getMultiDropAddonPrice(action.payload.item),
          total_price: getMultiDropAddonPrice(action.payload.item),
          addons: action.payload.item.addons,
          qty: 1,
          note: '',
        };
        const addData = [...state.holdQueue[multi_drop_add_item_index].data];
        addData[action.payload.index]['items'] = [
          ...addData[action.payload.index].items,
          item,
        ];
        return {
          ...state,
          holdQueue: [
            ...state.holdQueue.slice(0, multi_drop_add_item_index),
            {
              ...state.holdQueue[multi_drop_add_item_index],
              data: addData,
            },
            ...state.holdQueue.slice(multi_drop_add_item_index + 1),
          ],
        };
      }
    }
    case 'INCREMENT_ITEM_HOLD_QUEUE':
      const increment_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const increment_item_index = state.holdQueue[
        increment_index
      ].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );

      const increment_item = {
        ...state.holdQueue[increment_index].items[increment_item_index],
        qty:
          state.holdQueue[increment_index].items[increment_item_index].qty + 1,
        total_price:
          state.holdQueue[increment_index].items[increment_item_index]
            .item_price *
          (state.holdQueue[increment_index].items[increment_item_index].qty +
            1),
      };

      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, increment_index),
          {
            ...state.holdQueue[increment_index],
            items: [
              ...state.holdQueue[increment_index].items.slice(
                0,
                increment_item_index
              ),
              increment_item,
              ...state.holdQueue[increment_index].items.slice(
                increment_item_index + 1
              ),
            ],
          },
          ...state.holdQueue.slice(increment_index + 1),
        ],
      };
    case 'INCREMENT_MULTIDROP_ITEM_HOLD_QUEUE': {
      const increment_multidrop_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const increment_multidrop_item_index = state.holdQueue[
        increment_multidrop_index
      ].data[action.payload.index].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );

      const multidrop_increment_item = {
        ...state.holdQueue[increment_multidrop_index].data[action.payload.index]
          .items[increment_multidrop_item_index],
        qty:
          state.holdQueue[increment_multidrop_index].data[action.payload.index]
            .items[increment_multidrop_item_index].qty + 1,
        total_price:
          state.holdQueue[increment_multidrop_index].data[action.payload.index]
            .items[increment_multidrop_item_index].item_price *
          (state.holdQueue[increment_multidrop_index].data[action.payload.index]
            .items[increment_multidrop_item_index].qty +
            1),
      };

      const addData = [...state.holdQueue[increment_multidrop_index].data];
      addData[action.payload.index]['items'] = [
        ...addData[action.payload.index].items.slice(
          0,
          increment_multidrop_item_index
        ),
        multidrop_increment_item,
        ...addData[action.payload.index].items.slice(
          increment_multidrop_item_index + 1
        ),
      ];

      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, increment_multidrop_index),
          {
            ...state.holdQueue[increment_multidrop_index],
            data: addData,
          },
          ...state.holdQueue.slice(increment_multidrop_index + 1),
        ],
      };
    }
    case 'COMPLIMENT_ITEM_HOLD_QUEUE':
      const compliment_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const compliment_item_index = state.holdQueue[
        compliment_index
      ].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );
      const compliment_item = {
        ...state.holdQueue[compliment_index].items[compliment_item_index],
        is_complimentary:
          state.holdQueue[compliment_index].items[compliment_item_index] &&
          state.holdQueue[compliment_index].items[compliment_item_index]
            .is_complimentary
            ? false
            : true,
        complimentary_reason: action.payload.reason
          ? action.payload.reason
          : '',
      };
      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, compliment_index),
          {
            ...state.holdQueue[compliment_index],
            items: [
              ...state.holdQueue[compliment_index].items.slice(
                0,
                compliment_item_index
              ),
              compliment_item,
              ...state.holdQueue[compliment_index].items.slice(
                compliment_item_index + 1
              ),
            ],
          },
          ...state.holdQueue.slice(compliment_index + 1),
        ],
      };
    case 'UPDATE_NOTE_HOLD_QUEUE':
      const note_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const note_item_index = state.holdQueue[note_index].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );
      const note_item = {
        ...state.holdQueue[note_index].items[note_item_index],
        note: action.payload.value ? action.payload.value : '',
      };
      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, note_index),
          {
            ...state.holdQueue[note_index],
            items: [
              ...state.holdQueue[note_index].items.slice(0, note_item_index),
              note_item,
              ...state.holdQueue[note_index].items.slice(note_item_index + 1),
            ],
          },
          ...state.holdQueue.slice(note_index + 1),
        ],
      };
    case 'UPDATE_MULTIDROP_NOTE_HOLD_QUEUE': {
      const multidrop_note_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const multidrop_note_item_index = state.holdQueue[
        multidrop_note_index
      ].data[action.payload.index].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );
      const multidrop_note_item = {
        ...state.holdQueue[multidrop_note_index].data[action.payload.index]
          .items[multidrop_note_item_index],
        note: action.payload.value ? action.payload.value : '',
      };

      const noteData = [...state.holdQueue[multidrop_note_index].data];
      noteData[action.payload.index]['items'] = [
        ...noteData[action.payload.index].items.slice(
          0,
          multidrop_note_item_index
        ),
        multidrop_note_item,
        ...noteData[action.payload.index].items.slice(
          multidrop_note_item_index + 1
        ),
      ];
      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, multidrop_note_index),
          {
            ...state.holdQueue[multidrop_note_index],
            data: noteData,
          },
          ...state.holdQueue.slice(multidrop_note_index + 1),
        ],
      };
    }
    case 'DECREMENT_ITEM_HOLD_QUEUE':
      const decrement_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const decrement_item_index = state.holdQueue[
        decrement_index
      ].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );

      const currQty =
        state.holdQueue[decrement_index].items[decrement_item_index].qty;

      const decrement_item = {
        ...state.holdQueue[decrement_index].items[decrement_item_index],
        qty: currQty === 1 ? 1 : currQty - 1,
        total_price:
          state.holdQueue[decrement_index].items[decrement_item_index]
            .item_price * (currQty === 1 ? 1 : currQty - 1),
      };

      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, decrement_index),
          {
            ...state.holdQueue[decrement_index],
            items: [
              ...state.holdQueue[decrement_index].items.slice(
                0,
                decrement_item_index
              ),
              decrement_item,
              ...state.holdQueue[decrement_index].items.slice(
                decrement_item_index + 1
              ),
            ],
          },
          ...state.holdQueue.slice(decrement_index + 1),
        ],
      };
    case 'DECREMENT_MULTIDROP_ITEM_HOLD_QUEUE': {
      const decrement_multidrop_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );
      const decrement_multidrop_item_index = state.holdQueue[
        decrement_multidrop_index
      ].data[action.payload.index].items.findIndex(
        (value) =>
          value.item_id === action.payload.item.item_id &&
          _.isEqual(value.addons, action.payload.item.addons)
      );

      const currQty =
        state.holdQueue[decrement_multidrop_index].data[action.payload.index]
          .items[decrement_multidrop_item_index].qty;

      const multidrop_decrement_item = {
        ...state.holdQueue[decrement_multidrop_index].data[action.payload.index]
          .items[decrement_multidrop_item_index],
        qty: currQty === 1 ? 1 : currQty - 1,
        total_price:
          state.holdQueue[decrement_multidrop_index].data[action.payload.index]
            .items[decrement_multidrop_item_index].item_price *
          (currQty === 1 ? 1 : currQty - 1),
      };

      const addData = [...state.holdQueue[decrement_multidrop_index].data];
      addData[action.payload.index]['items'] = [
        ...addData[action.payload.index].items.slice(
          0,
          decrement_multidrop_item_index
        ),
        multidrop_decrement_item,
        ...addData[action.payload.index].items.slice(
          decrement_multidrop_item_index + 1
        ),
      ];

      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, decrement_multidrop_index),
          {
            ...state.holdQueue[decrement_multidrop_index],
            data: addData,
          },
          ...state.holdQueue.slice(decrement_multidrop_index + 1),
        ],
      };
    }
    case 'REMOVE_ITEM_FROM_HOLD_QUEUE':
      const remove_item_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );

      const deleteItems = state.holdQueue[remove_item_index].items.filter(
        (val) =>
          val.item_id !== action.payload.item.item_id ||
          !_.isEqual(val.addons, action.payload.item.addons)
      );

      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, remove_item_index),
          {
            ...state.holdQueue[remove_item_index],
            items: deleteItems,
          },
          ...state.holdQueue.slice(remove_item_index + 1),
        ],
      };
    case 'REMOVE_ITEM_FROM_MULTIDROP_HOLD_QUEUE': {
      const remove_multidrop_item_index = state.holdQueue.findIndex(
        (val) => val._id === action.payload._id
      );

      const deleteItems = state.holdQueue[remove_multidrop_item_index].data[
        action.payload.index
      ].items.filter(
        (val) =>
          val.item_id !== action.payload.item.item_id ||
          !_.isEqual(val.addons, action.payload.item.addons)
      );

      const removeData = [...state.holdQueue[remove_multidrop_item_index].data];
      removeData[action.payload.index]['items'] = deleteItems;

      return {
        ...state,
        holdQueue: [
          ...state.holdQueue.slice(0, remove_multidrop_item_index),
          {
            ...state.holdQueue[remove_multidrop_item_index],
            data: removeData,
          },
          ...state.holdQueue.slice(remove_multidrop_item_index + 1),
        ],
      };
    }
    case 'REMOVE_HOLD_QUEUE': {
      const removeHoldQueue = [...state.holdQueue];
      const removeHoldQueue_index = removeHoldQueue.findIndex(
        (val) => val._id === action.payload
      );
      if (removeHoldQueue_index > -1) {
        removeHoldQueue.splice([removeHoldQueue_index], 1);
      }
      return {
        ...state,
        holdQueue: removeHoldQueue,
        currentSelectedHold: {},
        activeMultiDelivery: null,
      };
    }
    case actionTypes.LOG_OUT:
      return {
        currentSelectedHold: {},
        holdQueue: [],
        deliveryUsers: [],
      };
    case 'GET_DELIVERY_USERS':
      return {
        ...state,
        deliveryUsers: action.payload,
      };
    case 'INIT_SUBSCRIBERS':
      return {
        ...state,
        subscribers: action.payload,
      };
    case actionTypes.RESET_HOLD:
      return {
        currentSelectedHold: {},
        holdQueue: [],
        deliveryUsers: [],
        subscribers: [],
        activeMultiDelivery: null,
      };
    default:
      return state;
  }
};

export default reducer;

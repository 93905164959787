export const INIT_TABLE = 'INIT_TABLE';
export const INIT_WAITER = 'INIT_WAITER';
export const UPDATE_TABLE_CURRENT_ORDER = 'UPDATE_TABLE_CURRENT_ORDER';
export const CURRENT_SELECTED_TABLE = 'CURRENT_SELECTED_TABLE';
export const CREATE_DINEIN_QUEUE = 'CREATE_DINEIN_QUEUE';
export const ADD_ITEM_TO_DINEIN_QUEUE = 'ADD_ITEM_TO_DINEIN_QUEUE';
export const INCREMENT_ITEM_DINEIN_QUEUE = 'INCREMENT_ITEM_DINEIN_QUEUE';
export const COMPLIMENT_ITEM_DINEIN_QUEUE = 'COMPLIMENT_ITEM_DINEIN_QUEUE';
export const UPDATE_NOTE_DINEIN_QUEUE = 'UPDATE_NOTE_DINEIN_QUEUE';
export const DECREMENT_ITEM_DINEIN_QUEUE = 'DECREMENT_ITEM_DINEIN_QUEUE';
export const REMOVE_ITEM_FROM_DINEIN_QUEUE = 'REMOVE_ITEM_FROM_DINEIN_QUEUE';
export const REMOVE_DINEIN_QUEUE = 'REMOVE_DINEIN_QUEUE';
export const BOOKED_TABLE = 'BOOKED_TABLE';
export const REMOVE_BOOKED_TABLE = 'REMOVE_BOOKED_TABLE';
export const RESET_TABLE = 'RESET_TABLE';

import * as actionTypes from '../../actionTypes';

const initialState = {
  activeTables: [],
  userQueue: [],
};

export default function qmsReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_ACTIVE_TABLES:
      return {
        ...state,
        activeTables: action.payload,
      };
    case actionTypes.INIT_USER_QUEUE:
      return {
        ...state,
        userQueue: action.payload,
      };
    case actionTypes.REMOVE_USER_QUEUE:
      const tempQueue = [...state.userQueue];
      const tempQueueIndex = tempQueue.findIndex(
        (a) => a._id === action.payload
      );
      if (tempQueueIndex > -1) {
        tempQueue.splice(tempQueueIndex, 1);
      }
      return {
        ...state,
        userQueue: tempQueue,
      };
    case actionTypes.LOG_OUT:
      return {
        activeTables: [],
        userQueue: [],
      };
    default:
      return state;
  }
}

import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {cancelOrder, cancelUPOrder} from '../../../services/actions';

function CancelDialog({open, handleClose, orderID, urbanPiper}) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [cancelReason, setCancelReason] = useState('');

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('Reason to cancel order')}</DialogTitle>
      <DialogContent>
        <RadioGroup
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        >
          <FormControlLabel
            value="item_out_of_stock"
            control={<Radio color="primary" />}
            label={t('Item out of stock')}
          />
          <FormControlLabel
            value="store_closed"
            control={<Radio color="primary" />}
            label={t('Store closed')}
          />
          <FormControlLabel
            value="store_busy"
            control={<Radio color="primary" />}
            label={t('Store busy')}
          />
          <FormControlLabel
            value="rider_not_available"
            control={<Radio color="primary" />}
            label={t('Rider not available')}
          />
          <FormControlLabel
            value="out_of_delivery_radius"
            control={<Radio color="primary" />}
            label={t('Out of delivery radius')}
          />
          <FormControlLabel
            value="connectivity_issue"
            control={<Radio color="primary" />}
            label={t('Connectivity issue')}
          />
          <FormControlLabel
            value="total_missmatch"
            control={<Radio color="primary" />}
            label={t('Total missmatch')}
          />
          <FormControlLabel
            value="invalid_item"
            control={<Radio color="primary" />}
            label={t('Invalid item')}
          />
          <FormControlLabel
            value="option_out_of_stock"
            control={<Radio color="primary" />}
            label={t('Option out of stock')}
          />
          <FormControlLabel
            value="invalid_option"
            control={<Radio color="primary" />}
            label={t('Invalid option')}
          />
          <FormControlLabel
            value="unspecified"
            control={<Radio color="primary" />}
            label={t('unspecified')}
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          disabled={cancelReason === ''}
          size="small"
          onClick={() => {
            if (urbanPiper) {
              dispatch(cancelUPOrder(orderID, cancelReason));
            } else {
              dispatch(cancelOrder(orderID, cancelReason));
            }
            handleClose();
          }}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelDialog;
